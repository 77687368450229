import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './player.scss';
import {
  BsFillPlayCircleFill,
  BsFillPauseCircleFill,
  BsFillSkipStartCircleFill,
  BsFillSkipEndCircleFill
} from 'react-icons/bs';


const Player = ({ currentSong, isplaying, setisplaying, setCurrentSong, songs }) => {
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState('');
  const [audioRef, setAudioRef] = useState('');

  useEffect(() => {
    console.log('Current Song:', currentSong);
    if (currentSong && currentSong.song_link) {
      setUrl(currentSong.song_link);
    }
  }, [currentSong, songs]);

  const handlePlayPause = () => {
    if (currentSong && currentSong.song_link) {
      setUrl(currentSong.song_link);
      setisplaying((prevIsPlaying) => !prevIsPlaying);
    }
  };

  const handleProgress = (state) => {
    const { played } = state;
    setProgress(played * 100);
  };

  const handleEnded = () => {
    const currentIndex = songs.findIndex((song) => song === currentSong);
    const isLastSong = currentIndex === songs.length - 1;

    if (!isLastSong) {
      skipTo(1);
    } else {
      setUrl('');
      setisplaying(false);
      setCurrentSong(null);
    }
  };


  const skipTo = (direction) => {
    const currentIndex = songs.findIndex((song) => song === currentSong);
    const newIndex = (currentIndex + direction + songs.length) % songs.length;

    if (newIndex >= 0 && newIndex < songs.length) {
      setCurrentSong(songs[newIndex]);
      setUrl(songs[newIndex].song_link);
      setisplaying(true);
    }
  };

  const handlePrevious = () => {
    skipTo(-1);
  };

  const handleNext = () => {
    skipTo(1);
  };

  const handleSeek = (e) => {
    if (audioRef) {
      const seekTime = (e.nativeEvent.offsetX / e.target.offsetWidth) * audioRef.getDuration();
      audioRef.seekTo(seekTime);
    }
  };

  return (
    <div style={{padding:'1.5rem 1rem'}}>
      <div>
        {currentSong && currentSong.song ? (
          <img style={{height:'15.9rem',width:'17rem',borderRadius:'8px 8px 0 0'}} src={currentSong.image} alt="song profile" />
        ) : (
          <p className="song-title">No Song Selected</p>
        )}
      </div>
      <div className='player_container'  style={{ maxWidth: '500px', margin: 'auto',backgroundColor:'#181A22',border:'none' }}>
      <p style={{padding:'1px',margin:'2px',marginTop:'8px',whiteSpace:'nowrap',overflowX:'hidden',width:'100%',justifyContent:"center",display:'flex'}}>{currentSong.song}</p>
        <div className="controls" style={{ height: '5px' }}>
          <BsFillSkipStartCircleFill className='btn_action' style={{ height: "25px" }} onClick={handlePrevious} />
          {isplaying ? (
            <BsFillPauseCircleFill className='btn_action pp' style={{ height: "25px" }} onClick={handlePlayPause} />
          ) : (
            <BsFillPlayCircleFill className='btn_action pp' style={{ height: "25px" }} onClick={handlePlayPause} />
          )}
          <BsFillSkipEndCircleFill className='btn_action' style={{ height: "25px" }} onClick={handleNext} />
        </div>
        <div className="navigation" onClick={handleSeek}>
          <div className="navigation_wrapper">
            <div className="seek_bar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <ReactPlayer
        ref={(ref) => setAudioRef(ref)}
          url={url}
          playing={isplaying}
          onProgress={handleProgress}
          onEnded={handleEnded}
          width='0'
          height='0'
          volume={1}
        />
      </div>
    </div>
  );
};

export default Player;
