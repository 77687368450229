 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'MopeimIcon',
   shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
   fill: 'none',
   stroke: 'currentColor',
 strokeLinecap: 'round',
  strokeLinejoin:  'round',
 strokeWidth:  '2.25px',
}));

SvgIcon.defaultProps = {
 viewBox: '0 0 24 24',
  focusable: 'false',
 'aria-hidden': 'true',
 };

const Mopeim = (props) => {
 return (
     <SvgIcon {...props}>
      
    <path d="M21.2852 12.5001C20.4402 12.5001 19.6452 12.0606 19.2152 11.3575L16.0002 6.15244L12.7902 11.3575C12.3552 12.0655 11.5602 12.505 10.7152 12.505C10.4902 12.505 10.2652 12.4757 10.0502 12.4122L3.20016 10.4981V19.1895C3.20016 19.9073 3.70016 20.5323 4.41016 20.7032L15.2202 23.3448C15.7302 23.4669 16.2652 23.4669 16.7702 23.3448L27.5902 20.7032C28.3002 20.5274 28.8002 19.9024 28.8002 19.1895V10.4981L21.9502 12.4073C21.7352 12.4708 21.5102 12.5001 21.2852 12.5001ZM31.9152 7.02158L29.3402 2.00205C29.1852 1.69931 28.8502 1.52353 28.5052 1.56748L16.0002 3.1251L20.5852 10.5519C20.7752 10.8595 21.1552 11.006 21.5102 10.9083L31.4052 8.14951C31.9002 8.00791 32.1402 7.4708 31.9152 7.02158ZM2.66016 2.00205L0.0851632 7.02158C-0.144837 7.4708 0.100163 8.00791 0.590163 8.14463L10.4852 10.9034C10.8402 11.0011 11.2202 10.8546 11.4102 10.547L16.0002 3.1251L3.49016 1.56748C3.14516 1.52842 2.81516 1.69931 2.66016 2.00205Z" fill="white"/>
 

     </SvgIcon>
  );
};

 export default Mopeim;


// export default function Logo123() {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25" fill="#7D8FA5">
//         <g clip-path="url(#clip0_118_767)">
//           <path d="M21.2852 12.5001C20.4402 12.5001 19.6452 12.0606 19.2152 11.3575L16.0002 6.15244L12.7902 11.3575C12.3552 12.0655 11.5602 12.505 10.7152 12.505C10.4902 12.505 10.2652 12.4757 10.0502 12.4122L3.20016 10.4981V19.1895C3.20016 19.9073 3.70016 20.5323 4.41016 20.7032L15.2202 23.3448C15.7302 23.4669 16.2652 23.4669 16.7702 23.3448L27.5902 20.7032C28.3002 20.5274 28.8002 19.9024 28.8002 19.1895V10.4981L21.9502 12.4073C21.7352 12.4708 21.5102 12.5001 21.2852 12.5001ZM31.9152 7.02158L29.3402 2.00205C29.1852 1.69931 28.8502 1.52353 28.5052 1.56748L16.0002 3.1251L20.5852 10.5519C20.7752 10.8595 21.1552 11.006 21.5102 10.9083L31.4052 8.14951C31.9002 8.00791 32.1402 7.4708 31.9152 7.02158ZM2.66016 2.00205L0.0851632 7.02158C-0.144837 7.4708 0.100163 8.00791 0.590163 8.14463L10.4852 10.9034C10.8402 11.0011 11.2202 10.8546 11.4102 10.547L16.0002 3.1251L3.49016 1.56748C3.14516 1.52842 2.81516 1.69931 2.66016 2.00205Z" fill="white"/>
//         </g>
//         <defs>
//           <clipPath id="clip0_118_767">
//             <rect width="32" height="25" fill="white"/>
//           </clipPath>
//         </defs>
//       </svg>
//     );
//   }