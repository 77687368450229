import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import WebIcon from '@mui/icons-material/Web';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import Logo123 from "./productimage";
import './dash.css';
import totalproducts from "./totalproducts.svg";
import collecticon from "./dashcollectible.svg";
import Collectible1 from "../../Assets/Collectible1.svg"
import webdashicon from "./webdashicon.svg";
import albumicondash from "./albumicondash.svg";
import React, { useState, useRef, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { urlchange } from "../../Globalvalues";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const token = sessionStorage.getItem("token");
  const theme = useTheme();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [product, setproduct] = useState('');
  const [album, setalbum] = useState('');
  const [webpage, setwebpage] = useState('');
  const [totalTap, setTotalTap] = useState('')
  const [collectible, setcollectible] = useState('');
  const [albumName, setAlbumName] = useState('');
  const [playCount, setPlayCount] = useState('');
  const [topSongsData, setTopSongsData] = useState([]);
  const [valid, setValid] = useState(false)
  const [songName, setSongName] = useState('');
  const colors = tokens(theme.palette.mode);

  const getalbum = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/dashboard`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {

      console.log("no");

    }


    else {
      console.log(data)
      setalbum(data.data.album_count);
      //  var albumcount=data.album_count;
      setcollectible(data.data.collectibles_count);
      setproduct(data.data.product_count);
      setwebpage(data.data.total_webpage);
      setTotalTap(data.data.total_tap);

      setDataLoaded(true);
    }
  }
  useEffect(() => {

    getalbum();
  }, []);

  // const topsongs = async () => {
  //   const requestOptions1 = {
  //     method: "GET",
  //     headers: { "Authorization": `Bearer ${token}` }

  //   }
  //   const response = await fetch(`${urlchange}/top-songs`, requestOptions1);
  //   const data1 = await response.json();
  //   if (!response.ok) {

  //     console.log("no");

  //   }

  //   else {
  //     console.log(data1);
  //     setAlbumName(data1.data.albumname);
  //     setPlayCount(data1.data.playcount);
  //     setSongName(data1.data.song);
  //     setDataLoaded(true);
  //     console.log(albumName);
  //     console.log(playCount);
  //   }
  // }

  const topsongs = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/top-songs`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log("no");
      setValid(false);
    }


    else {
      console.log(data)
      // setAlbumName(data.data);
      setTopSongsData(data.data);
      setValid(true);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    topsongs();
  }, []);


  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh',backgroundColor:'#000000' }}><ProgressSpinner /></div>;
  }
  
  return (
    <Box sx={{ overflowY: 'auto', height: '92.7vh', backgroundColor: '#0b0d0f' }}>
      <div className="header-grid-dash">
        <div className="header-text-dash">Dashboard</div>
        {/* <div className="icons-grid-header">
          <div className="button-div-header">
            <div className="button-text-div" onClick={navigatetoadd}>+ Create Album</div>
            </div><div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
            </div>
            </div> */}
      </div>
      <div className="content-div-dash">
        <div className="content-grid-dash">
          <div className="content-div-products">
            <img src={totalproducts} className="content-img" alt="Girl in a jacket" />
            <div className="content-text">Total Products</div>
            <div className="content-product-count">{product}</div>
          </div>
          <div className="dash-flex-box">
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <div className="dash-flex-item">
                <img src={albumicondash} className="dash-collectible-icon" alt="Girl in a jacket" />
                <div className="dash-coolectible-text">Albums</div>
                <div className="dash-collectible-count">{album}</div>
              </div>
              <div className="dash-flex-item">
                <img src={webdashicon} className="dash-collectible-icon" alt="Girl in a jacket" />
                <div className="dash-coolectible-text">Webpages</div>
                <div className="dash-collectible-count">{webpage}</div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: "1.5rem" }}>
              <div className="dash-flex-item">
                <img src={Collectible1} className="dash-collectible-icon" alt="Girl in a jacket" />
                <div className="dash-coolectible-text">Collectibles</div>
                <div className="dash-collectible-count">{collectible}</div>
              </div>
              <div className="dash-flex-item">
                <img src={collecticon} className="dash-collectible-icon" alt="Girl in a jacket" />
                <div className="dash-coolectible-text">Total Taps</div>
                <div className="dash-collectible-count">{totalTap}</div>
              </div>
            </div>
          </div>

          {/* <div className="flex-empty-box"></div> */}
        </div>
        <div style={{ paddingBottom: "3rem", backgroundColor: "white", marginTop: '1.5rem', borderRadius: '0.8rem', backgroundColor: '#181A22'}} className="table-view">
          {valid ?
            <>
              <p style={{ color: "#1F384C", paddingTop: "1rem", fontWeight: "bold", fontSize: '14px', paddingLeft: '1rem',color:'#FAFAFA', fontWeight: 'normal' }}>Top Played Songs</p>
              <table style={{ color: 'black' }} className="table-list">
                <thead>
                  <tr>
                    <th style={{ padding: "8px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>Album Name</th>
                    <th style={{ padding: "8px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>Song Name</th>
                    <th style={{ padding: "8px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>Play Count</th>
                    {/* Add more header columns as needed */}
                  </tr>
                </thead>
                <tbody style={{ color: "black" }}>
                  {topSongsData.map((song, index) => (
                    <tr key={index}>
                      <td style={{ borderBottom: "1px solid #181A22", padding: "8px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>{song.albumname}</td>
                      <td style={{ borderBottom: "1px solid #181A22", padding: "8px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>{song.song}</td>
                      <td style={{ borderBottom: "1px solid #181A22", padding: "8px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: '#FAFAFA', fontWeight: 'normal' }}>{song.playcount || 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
            : <p style={{ color: '#7D8FA5', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 'bold', paddingTop: '2rem' }}>Songs Not Available for the Particular Album</p>}
        </div>
      </div>
    </Box>
  );
};

export default Dashboard;
