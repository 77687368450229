import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import webpageicon from "./webpageicon.png";

import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import collectibleicon from "./collectibleicon.svg";
import albumicon from "./albumicon.svg";
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import React, { useState, useRef, useEffect } from 'react';
import styles from "./Productdetails.module.css";
import { ProgressBar } from 'primereact/progressbar';
import whitealbumicon from "./neededicon.svg";
import { songsdata } from "../Player/audios";
import website from "./web1.svg";
import { urlchange } from "../../Globalvalues";
import { useLocation } from "react-router-dom";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import backbutton from "../../Assets/backbutton.svg";
import { ProgressSpinner } from 'primereact/progressspinner';

import { TabView, TabPanel } from 'primereact/tabview';


const Team = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const location = useLocation();
  const token = sessionStorage.getItem("token");
  function refreshPage() {
    window.location.reload(true);
  }
  const [collectibleopen, setcollectibleopen] = useState(false);
  const [albumsopen, setalbumopen] = useState(false);
  const [webpageopen, setwebpageopen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const data123 = location.state.id;

  // const [items, setitems] = useState(null);
  const [items, setitems] = useState(null);

  const [first, setfirst] = useState(0);

  const [rows, setrows] = useState(5);

  const dateObject = new Date(data123.created_on);

  const formattedDate = dateObject.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const handlecollectibleopen = () => {
    console.log(data123)
    setcollectibleopen(true);
    setalbumopen(false);
    setwebpageopen(false);
  }
  const handlealbumopen = () => {
    console.log(data123)
    setcollectibleopen(false);
    setalbumopen(true);
    setwebpageopen(false);
  }
  const handlewebpageopen = () => {
    setcollectibleopen(false);
    setalbumopen(false);
    setwebpageopen(true);
  }

  function navigatetoadd() {

    navigate('/products');
  }
  useEffect(() => {

    setitems(data123);
    setcollectibleopen(true);
  }, []);
  // if (!dataLoaded) {
  //   return <div className="spinner_album" >    <ProgressSpinner /></div>;
  // }
  // if (!songuploaded) {
  //   return   <div className="card">
  //   <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
  // </div>
console.log(data123.albumid_name);


  return (

    <Box sx={{ overflowY: 'auto', height: '92.8vh', backgroundColor: '#0b0d0f' }}>
      <div className={styles.header_product_details}> <img className="song-back-button" onClick={navigatetoadd} src={backbutton} alt="img" /> Product Details</div>
      <div className={styles.main_produtdisplay_div} >
        <div className={styles.product_display_grid}>
          <div className={styles.product_display_grid_name}>{data123.product}</div>
          <div className={styles.product_display_grid_timetag}>{formattedDate}</div>
          <div className={styles.product_tag_icon_grid}>
            <div className={styles.product_display_grid_musicicontag}>
              <img src={whitealbumicon} alt="img" className={styles.product_display_grid_icon} />
              <div className={styles.product_display_grid_text}>{data123.number_of_songs}</div>
            </div>
            <div className={styles.product_display_grid_musicicontag}>
              <img src={website} alt="img" className={styles.product_display_grid_icon} />
              <div className={styles.product_display_grid_text}>{data123.number_of_webpage}</div>
            </div>
            <div className={styles.product_display_grid_musicicontag}>
              <img src={collectibleicon} alt="img" className={styles.product_display_grid_icon} />
              <div className={styles.product_display_grid_text}>{data123.number_of_collectibles}</div>
            </div>
          </div>
        </div>
        {/* <div className={styles.content_grid_product_display}>
    <div className={styles.header_flex_product_display}>
<div className={styles.header_flex_collectible_div} onClick={handlecollectibleopen}>
    <img className={styles.collectible_header_icon_display} src={collectibleicon} alt="alt img"/>
    <div className={styles.collectible_header_text_display}>Collectibles</div>

</div>
<div  className={styles.header_flex_albumns_div} onClick={handlealbumopen}>
<img className={styles.albumns_header_icon_display} src={albumicon} alt="alt img"/>
    <div className={styles.albumns_header_text_display}>Album</div>


</div>
<div className={styles.header_flex_webpage_div} onClick={handlewebpageopen}><img className={styles.webpage_header_icon_display} src={albumicon} alt="alt img"/>
    <div className={styles.webpage_header_text_display}>Webpage</div></div>



    </div>
    <div className={styles.content_display_grid_products}>
    {collectibleopen && items  && items.collectiblesid.map((item)=>( <div className={styles.content_display_collectible_div}>
            <img className={styles.content_display_collectible_div_img}  src={item.collectible_image} alt="alt img"/>
            <div className={styles.content_display_collectible_div_colllectiblename}>{item.collectible_name}</div>
            <div className={styles.content_display_Description_div}>Character with happy face </div>
        </div>))}
  {albumsopen &&  <div className={styles.content_display_collectible_div} >
    <img className={styles.content_display_collectible_div_img}  src={data123.productpic} alt="alt img"/>
            <div className={styles.content_display_collectible_div_colllectiblename}>{data123.albumid_name}</div>
           
    </div>}
  {webpageopen &&  <div className={styles.content_display_collectible_div} >
    <img className={styles.content_display_collectible_div_img}  src={webpageicon} alt="alt img"/>
            <div className={styles.content_display_collectible_div_colllectiblename}>{data123.website_name}</div>
         
    </div>}
        
   
        
        </div>
   </div> */}

        <div className={styles.content_display_div_grid}>
          <div className={styles.content_display_header_flex} >
            <div onClick={handlecollectibleopen} className={styles.content_display_header_items}>
              <img className={styles.content_display_header_items_icon} src={collectibleicon} alt="img" />
              <div className={styles.content_display_header_items_name} >Collectibles</div>
            </div>
            <div onClick={handlealbumopen} className={styles.content_display_header_items}>
              <img className={styles.content_display_header_items_icon} src={whitealbumicon} alt="img" />
              <div className={styles.content_display_header_items_name} >Albums</div>
            </div>
            <div onClick={handlewebpageopen} className={styles.content_display_header_items}>
              <img className={styles.content_display_header_items_icon} src={website} alt="img" />
              <div className={styles.content_display_header_items_name} >Webpages</div>
            </div>
          </div>
          {collectibleopen && (
            <>
              {data123.collectiblesid.length === 0 ? (
                <div className={styles.no_data_message}>No Collectibles assigned.</div>
              ) : (data123.collectiblesid.map((item) => (
                <div className={styles.content_display_dataitems}>
                  <img src={item.collectible_image} alt="img" className={styles.content_display_dataitems_img} />
                  <div className={styles.content_display_dataitems_name}>{item.collectible_name}</div>
                  <div className={styles.content_display_dataitems_description}>description</div>
                </div>))
              )}
            </>
          )}
          {albumsopen && (
            <>
              {data123.albumid_name ? (
                <div className={styles.content_display_dataitems}>
                  <img src={data123.productpic} alt="img" className={styles.content_display_dataitems_img} />
                  <div className={styles.content_display_dataitems_name}>{data123.albumid_name}</div>
                  <div className={styles.content_display_dataitems_description}>artistname</div>
                </div>

              ) : (
                <div className={styles.no_data_message}>No album assigned</div>
              )}
            </>
          )}
          {webpageopen && (
            <>
              {data123.website_name ? (<div className={styles.content_display_dataitems}>
                <img src={webpageicon} alt="img" className={styles.content_display_dataitems_img} />
                <div className={styles.content_display_dataitems_name}>{data123.website_name}</div>
                {/* <div className={styles.content_display_dataitems_description}></div> */}
              </div>

              ) : (
                <div className={styles.no_data_message}>No webpage data available.</div>
              )}
            </>
          )}
          {/* 
   <div className={styles.content_display_dataitems}>
<img src={collectibleicon} alt="img" className={styles.content_display_dataitems_img}/>
<div className={styles.content_display_dataitems_name}>Character</div>
<div className={styles.content_display_dataitems_description}>description</div>
   </div> */}

        </div>
      </div>
    </Box>
  );
};

export default Team;
