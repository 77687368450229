import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import { InputSwitch } from 'primereact/inputswitch';
import addicon from "./addicon.svg";


import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import closeicon from "./closeicon.svg";
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import React, { useState, useRef, useEffect } from 'react';
import settings from "./settingsicon.svg";
import { ProgressBar } from 'primereact/progressbar';

import { songsdata } from "../Player/audios";

import { urlchange } from "../../Globalvalues";
import { useLocation } from "react-router-dom";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';

import { ProgressSpinner } from 'primereact/progressspinner';

import { TabView, TabPanel } from 'primereact/tabview';
import styles from './tags.module.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


const Team = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const [opencreate, setOpencreate] = React.useState(false);
  const handleOpen = () => setOpencreate(true);
  const handleClose = () => {
    setOpencreate(false);
    setpath1('');
  };
  const [opencreate1, setOpencreate1] = React.useState(false);
  const handleOpen1 = () => {
    setOpencreate1(true);
    handleClose(false);
  }
  const handleClose1 = () => setOpencreate1(false);
  const [opencreate2, setOpencreate2] = React.useState(false);
  const handleOpen2 = () => {
    setOpencreate2(true);
    setOpencreate1(false);
  }
  const handleClose2 = () => setOpencreate2(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const token = sessionStorage.getItem("token");
  const [artist, setartist] = useState();
  const [uploadid, setuploadid] = useState();
  const [path1, setpath1] = useState();
  const toast = useRef(null);
  const showSuccess = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess12 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 220,
    bgcolor: 'white',
    // border: '2px solid #000',
    borderRadius: '0.78rem',
    backgroundColor:'#181A22',
    border:'2px solid #FAFAFA'
  }
  function navigatetoadd(id) {

    navigate('/tagsdetails', { state: { id } });
  }

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Check if the selected file type is an Excel file
      if (
        // !selectedFile.type.includes('spreadsheetml.sheet') &&
        // !selectedFile.name.endsWith('.xls') &&
        !selectedFile.name.endsWith('.xlsx')
      ) {
        // If not an Excel file, show an alert or perform any other error handling
        alert('Please select a valid Excel file.');
        // Reset the input field
        e.target.value = null;
      } else {
        // Proceed with handling the Excel file
        setpath1(selectedFile);
      }
    }
  };

  const getartist = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }
    }

    const response = await fetch(`${urlchange}/tag_count_allartist`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log("no");
      setDataLoaded(true);
    }

    else {
      console.log(data.data)
      setartist(data.data);
      setDataLoaded(true);
      setpath1('');
    }
  }

  const uploadtags = async () => {

    if (!path1) {
      alert('Please Select the file!')
    } else {
      const formData = new FormData()
      // console.log(file)
      formData.append(
        'file',
        path1

      )
      formData.append(
        'artistid',
        uploadid
      )
      const requestOptions1 = {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}` },
        body:
          formData
      }

      const response1 = await fetch(`${urlchange}/upload_tag`, requestOptions1);

      const data1 = await response1.json();

      if (!response1.ok) {
        console.log(data1.message);
        showSuccess12(data1.message)
      }
      else {
        showSuccess(data1.message);
        handleClose();
        getartist();
      }
    }
  }

  const handleupdate = (id) => {
    setuploadid(id);
    handleOpen();
  };

  useEffect(() => {
    getartist();
  }, []);

  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh',backgroundColor:'#000000' }}>    <ProgressSpinner /></div>;
  }

  return (
    <Box style={{ overflow: "auto", height: "92.70vh", backgroundColor: '#000000' }} >
      <div className={styles.main_header_text_grid}>
        <div className={styles.main_haeder_text}>Tags</div></div>
      <div className={styles.table_flex_main}>
        <div style={{ width: '90%' }}>
          <DataTable value={artist} sortOrder={1} sortField="artistname" paginator rows={5} tableStyle={{ width: '100%' }} rowHeight="10px" rowClassName={styles.custom_datatable_row}>
            <Column field="artistname" header="Name" headerStyle={{ textAlign: 'center', background: '#7D8FA5',color:'#FAFAFA',fontWeight:'normal' }} style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            <Column field="totaltag" headerStyle={{ textAlign: 'center', background: '#7D8FA5',fontWeight:'normal',color:'#FAFAFA' }} header="Total" style={{ width: '20%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            <Column field="totalused" headerStyle={{ textAlign: 'center', background: '#7D8FA5',fontWeight:'normal',color:'#FAFAFA' }} header="Used" style={{ width: '20%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            <Column field="totalnotused" headerStyle={{ textAlign: 'center', background: '#7D8FA5',fontWeight:'normal',color:'#FAFAFA' }} header="Unused" style={{ width: '20%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            {/* <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Remaining" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column> */}
            {/* <Column headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Actions" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto'  }} body={(rowData) => ( <img src={addicon}  onClick={() => handleupdate(rowData.userid)}   style={{ transform: 'scale(0.5)',marginRight: '5px' }}/>  )  }></Column> */}
            <Column
              headerStyle={{ textAlign: 'center', background: '#7D8FA5',fontWeight:'normal',color:'#FAFAFA' }}
              header="Actions"
              style={{ width: '40%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}
              body={(rowData) => (
                <>
                  <img
                    src={addicon}
                    alt="Add Icon"
                    className={styles.icon_tooltip}
                    onClick={() => handleupdate(rowData.artistid
                    )}
                    style={{ transform: 'scale(0.5)', marginRight: '1px' }}
                  />
                  <img
                    src={settings}
                    alt="Another Icon"
                    className={styles.icon_tooltip}
                    onClick={() => navigatetoadd(rowData.artistid
                    )}
                  // style={{ transform: 'scale(0.5)' }}
                  />
                </>
              )}
            />

          </DataTable>
        </div></div>
      <Modal
        open={opencreate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.modal_header_albums}>
            <div className={styles.modal_header_text}>Upload Tags</div>
            {/* <img src={closeicon} alt="img" className={styles.modal_close_icon} /> */}
            <div className={styles.modal_input_label}>Upload File</div>
            <input type="file" id="lname" accept=".xlsx" onChange={(e) => handleFileUpload(e)} className={styles.modal_input_artistname} name="lname" />
            <button type="button" className={styles.modal_input_nextbutton} onClick={uploadtags}>Upload</button>
            <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>
      {/* <Modal
        open={opencreate1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Email Address</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname"/>
     <button type="button" className={styles.modal_input_nextbutton} onClick={handleOpen2}>Next</button>
     <button type="button" className={styles.modal_input_cancelbutton}  onClick={handleClose1}>Cancel</button>
     </div>
        </Box>
      </Modal>
      <Modal
        open={opencreate2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Phone Number</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname"/>
     <button type="button" className={styles.modal_input_nextbutton} >Create</button>
     <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose2}>Cancel</button>
     </div>
        </Box>
      </Modal> */}
      <Toast ref={toast} />
    </Box>
  );
};

export default Team;
