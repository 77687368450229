import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import './webpage.css';
import editbutton from "./editbutton.svg";
import deletebutton from "./deletebutton.svg";
import albumicon from "./webpageicon.svg";
import songimg from "./songimg.png";
import settings from "./settings.svg";
import webimg from "./webimg.png";
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import Modal from '@mui/material/Modal';
import { Paginator } from 'primereact/paginator';
import { urlchange } from "../../Globalvalues";
import { ProgressSpinner } from 'primereact/progressspinner';

const Team = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];
  const [delid, setdelid] = useState('');
  const token = sessionStorage.getItem("token");
  const [songuploaded, setsonguploaded] = useState(true);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setwebname('');
    setpath1('');
  };
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);
  const [webname, setwebname] = useState("");
  const [updatewebname, setupdatewebname] = useState("");
  const [updateid, setupdateid] = useState("");
  var [path1, setpath1] = useState();
  function refreshPage() {
    window.location.reload(true);
  }
  const accept = (id) => {

    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    deletealbum(id);
  }
  const confirm2 = (id) => {

    confirmDialog({
      message: 'Do you want to delete this Webpage?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),

    });
  };
  const handleOpen1 = () => {
    setOpen(false);
    setOpen1(true);
  }
  const handleOpen2 = () => {
    setOpen2(true);
  }
  const handleClose1 = () => setOpen1(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [first, setfirst] = useState(0);
  const [items, setitems] = useState();
  const [rows, setrows] = useState(5);
  const onUpload = () => {
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };
  const showSuccess = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess2 = () => {
    toast.current.show({ severity: 'error', summary: 'Success', detail: 'Webpage deleted successfully', life: 3000 });
  }
  const showSuccess3 = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Webpage Updated Successfully', life: 3000 });
  }
  const showSuccess4 = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess23 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Success', detail: msg, life: 3000 });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 280,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border:'2px solid white'
  };

  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 220,
    bgcolor: 'white',
    // border: '2px solid #000',
    borderRadius: '0.625rem',
    backgroundColor:'#181A22'
  };

  const getalbum = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/webpage`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
      setDataLoaded(true);
    }
    else {

      console.log(data.data)
      setitems(data.data);
      setDataLoaded(true);
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file type is HTML
      if (file.type === 'text/html') {
        setpath1(file);
      } else {
        alert('Please select a valid HTML file.');
        // Clear the input field
        event.target.value = null;
        setpath1(null);
      }
    }
  };

  const createstateuser = async () => {

    // console.log(file)
    //   formData.append(
    //     'image',
    //     path1
    //     // path1.name,
    //         )
    //         const requestOptions1 = {
    //           method: "POST",
    //           headers: {"Authorization":"Bearer"+{token} },
    //           body: 
    //           formData

    //          }

    // const response1 = await fetch(`${urlchange}/upload_image`,requestOptions1);

    //   const data1 = await response1.json();
    // const   image_ex=data1.image_url;
    //   console.log(image_ex);


    var datauser =
    {
      "webpage": webname
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        datauser
      ),
    };

    const response = await fetch(`${urlchange}/create_webpage`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
    }
    else {
      showSuccess(data.message);
    }
    const webid = data.webpage_id;

    const formData = new FormData()
    // console.log(file)
    formData.append('file', path1);
    formData.append('wpid', webid);
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": `Bearer ${token}` },
      body:
        formData
    }

    const response1 = await fetch(`${urlchange}/add_template`, requestOptions1);

    const data1 = await response1.json();
    // const   image_ex=data1.image_url;
    //   console.log(image_ex);
    if (!response1.ok) {
      console.log("no");
      showSuccess23(data1.message);
      // setwebname('')
      // setpath1('')
    }
    else {
      showSuccess4(data1.message);
      handleClose();
      getalbum();
    }
  }

  const deletealbum = async (id) => {
    const formData = new FormData()
    // console.log(file)


    const requestOptions1 = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${token}` },


    }

    const response1 = await fetch(`${urlchange}/deleted_webpage?webpage_id=${id}`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      console.log(data1);
    }
    else {


      // getalbum();
      showSuccess2(data1.message);
      refreshPage();
    }





  }
  const handleNextClick = () => {
    // Check if the album name is empty
    if (!webname.trim()) {
      // Show an error message (you can replace this with your own logic)
      alert('Please enter a name.');
      return;
      // showstopmsg();
      // return;
    }

    // Continue with the next step or action
    // For example, you can call handleOpen1() here
    handleOpen1();
  };

  const handleNextClick1 = () => {

    if (!webname.trim()) {
      // Show an error message (you can replace this with your own logic)
      alert('Please enter a name.');
      return;
      // showstopmsg();
      // return;
    }

    // Check if the album name is empty
    // if (!path1) {
    //   // Show an error message (you can replace this with your own logic)
    //   alert('Please upload a file.');
    //   return;
    //   // showstopmsg();
    //   // return;
    // }

    if (path1) {
      // Perform your submission logic here
      console.log('Submitting HTML file:', path1);
      setsonguploaded(false);
      createstateuser();
    } else {
      alert('Please select a valid HTML file.');
    }

    // Continue with the next step or action
    // For example, you can call handleOpen1() here

  };

  const updatewebpage = async () => {

    // console.log(file)
    const requestOptions1 = {
      method: "PUT",
      headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify(
        {
          "webpage": updatewebname
        }
      )
    }

    const response1 = await fetch(`${urlchange}/update_webpage?webpage_id=${updateid}`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      console.log(data1);
    }
    else {
      showSuccess3();
      handleClose2();
      getalbum();
    }
  }

  const handleupdate = async (id) => {
    handleOpen2();
    setupdateid(id.id);
    setupdatewebname(id.webpage);
  }
  useEffect(() => {

    getalbum();
  }, []);
  
  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh',backgroundColor:'#000000' }}>    <ProgressSpinner /></div>;
  }

  return (

    <Box sx={{ overflowY: 'auto', height: '92.8vh',backgroundColor:'#0b0d0f' }}>
      <div className="header-grid-web">
        <div className="header-text-web">Webpages</div>
        <div className="icons-grid-header-web">
          <div className="button-div-header-web">
            <div className="button-text-div-web" onClick={handleOpen}>+ Webpage</div>
            {/* </div><div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div> */}
          </div>
        </div>
      </div>
      <div className="content-div-web" >
        <div className="content-grid-web">

          <div className="album-flex-box-web">
            {items
              ? items.slice(first, first + rows).map((item) => (<div className="album-item-web">
                <div className="album-name-div-web">{item.webpage}</div>
                <div className="album-date-div-web">{item.created_on}</div>
                <img src={deletebutton} onClick={() => confirm2(item.id)} className="webpage-delete-icon-new" alt="Girl in a jacket" />
                <img src={editbutton} onClick={() => handleupdate(item)} className="webpage-edit-icon-new" alt="Girl in a jacket" />
                {/* <div className="tag-div"><img src={albumicon} className="album-icon" alt="Girl in a jacket"/><div className="album-text">01</div></div> */}
              </div>)) : <p className="text-new-webpage"> No Webpages available</p>}
            {items ? <Paginator first={first} rows={rows} totalRecords={items.length} onPageChange={(e) => {
              setfirst(e.first);
              setrows(e.rows);
            }} /> : <p></p>}
          </div>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div className="modal-header-webpages">
              <div className="modal-header-text-webpages">Add Webpage</div>
            </div>
            <div className="modal-input-name-webpages">Enter Website Details</div>
            <input type="text" placeholder="Enter the Name" className="modal-input-field-webpages" maxLength={30} onChange={(e) => setwebname(e.target.value)} id="fname" name="fname" />
            <input type="file" className="modal-webpage-pageupload" accept=".html" onChange={handleFileChange} id="lname" name="lname" />
            {/* <Image src={imagepreview} className="modal-preview-img" width="150"  alt="Girl in a jacket"/> */}
            <button className="modal-button-next-webpages" onClick={handleNextClick1}>Create</button>
            <button className="modal-button-next2-webpages" onClick={handleClose} >Cancel</button>
          </Box>
        </Modal>

        {/* <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {songuploaded ? (     <div>
        <div className="modal-header-webpages">
          <div className="modal-header-text-webpages">Add Webpages</div>
        </div>
        <div className="modal-input-name-webpages1">Upload Html</div>
        <input type="file" className="modal-webpage-pageupload"  onChange={(e) => setpath1(e.target.files[0])}  id="lname"  name="lname"/>

       <button className="modal-button-next-webpages" onClick={handleNextClick1}>Create</button>
       <button className="modal-button-next2-webpages" onClick={handleClose1} >Cancel</button></div>): (<div  ><ProgressSpinner style={{ width: '40px', height: '40px' }} /></div> )}
        </Box>
        
      </Modal> */}
        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1}>
            <div className="modal-header-webpages">
              <div className="modal-header-text-webpages">Update Webpage</div>
            </div>
            <div className="modal-input-name-webpages2">Update Webpage</div>
            <input type="text" className="modal-webpage-pageupload1" value={updatewebname} maxLength={30} onChange={(e) => setupdatewebname(e.target.value)} id="lname" name="lname" />
            {/* <FileUpload mode="basic" name="demo[]" className="modal-upload-button" url="/api/upload" accept="image/*" maxFileSize={1000000} onUpload={onUpload} /> */}
            {/* <Image src={imagepreview} className="modal-preview-img" width="150"  alt="Girl in a jacket"/> */}
            <button className="modal-button-next-webpages1" onClick={updatewebpage} >Update</button>
            <button className="modal-button-next2-webpages1" onClick={handleClose2} >Cancel</button>
          </Box>

        </Modal>
        <Toast ref={toast} />
        <ConfirmDialog />
      </div>
    </Box>
  );
};

export default Team;
