import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import "./contacts.css";
import Overlay from "../Overlay";
import React, { useState, useRef, useEffect } from 'react';
import deletebutton from "./deletebutton.svg";
import playButton from "../../Assets/playButton.svg"
import pointerButton from "../../Assets/pointerButton.svg"
import editicon from "./editbutton.svg";
import { useNavigate } from 'react-router-dom';
import songimg from "./songimg.png";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import { urlchange } from "../../Globalvalues";
import { FaPlay, FaPause } from 'react-icons/fa';
import Player from "../Player/player";
import ReactAudioPlayer from 'react-audio-player';
import albumicon from "./albumicon.svg";
import backbutton from "../../Assets/backbutton.svg";


const Contacts = () => {
  const theme = useTheme();
  const token = sessionStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [items, setitems] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const toast = useRef(null);
  const [delid, setdelid] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isplaying, setisplaying] = useState(false);
  const [currentSong, setCurrentSong] = useState();
  const [songs, setSongs] = useState();


  const audioElem = useRef();


  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Album created', life: 3000 });
  }
  const location = useLocation();
  const data123 = location.state.id;
  function refreshPage() {
    window.location.reload(true);
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  function navigatetoadd() {
    navigate('/albums');
  }

  // function navigatetoalbums() {
  //   navigate('/albums');
  // }

  const togglePlayPause = () => {
    setisplaying((prevIsPlaying) => !prevIsPlaying);
  };

  const handlePlayPause = (song, song_link, index, image) => {
    setIsPlaying(true);
    setCurrentSong({
      song: song,
      song_link: song_link,
      index: index,
      image: image
    });

    if (audioElem.current.src !== song_link) {
      audioElem.current.src = song_link;
      audioElem.current.load();
    }

    audioElem.current.play().catch((error) => {
      console.error('Error playing audio:', error.message);
      setIsPlaying(false);
    });
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },

    {
      field: "name",
      headerName: "Album name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Count",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Actions",
      headerAlign: "center",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div>

          <img src={deletebutton} style={{ cursor: "pointer" }} alt="Trulli" />
          <img src={editicon} alt="Trulli" style={{ marginLeft: 13, cursor: "pointer" }} onClick={navigatetoadd} />

        </div>
      ),
    },


  ];
  const showSuccess2 = () => {
    toast.current.show({ severity: 'error', summary: 'Success', detail: 'Song deleted', life: 3000 });
  }

  const accept = () => {

    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    deletealbum(delid);
  }
  const confirm2 = (id) => {
    console.log(id);
    setdelid(id);
    confirmDialog({
      message: 'Do you want to delete this Song?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
    });
  };
  const getalbum = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/song?albumid=${data123}`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
      setDataLoaded(true);
    }
    else {


      console.log(data.data)
      setitems(data.data);
      setDataLoaded(true);
    }
  }
  // const toggleOverlay = () => {
  //   setIsOpen(!isOpen);
  // };
  const [first, setfirst] = useState(0);
  const [rows, setrows] = useState(5);
  // const items = [
  //   {
  //     "userId": 1,
  //     "id": 1,
  //     "title": "Radioactive",
  //     "body": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
  //   },
  //   
  // ]

  useEffect(() => {

    getalbum();
  }, []);

  const deletealbum = async (id) => {

    // console.log(file)


    const requestOptions1 = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${token}` },


    }

    const response1 = await fetch(`${urlchange}/deleted_song?song_id=${id}`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      console.log(data1);
    }
    else {

      showSuccess2();

      refreshPage();

    }





  }
  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh',backgroundColor:'#000000' }}>    <ProgressSpinner /></div>;
  }
  return (

    <Box sx={{ overflowY: 'auto', height: '92.8vh',backgroundColor:'#0b0d0f' }}>
      <div className="header-grid-contacts">
        {/* <div className="header-text-contacts">Edit Albums</div>
         */}
        <div className="header-text-contacts"> <img className="song-back-button1" onClick={navigatetoadd} src={backbutton} alt="img" /> Songs</div>
        {/* <div className="icons-grid-header-contacts">
      <div className="button-div-header-contacts">
        <div className="button-text-div-contacts" onClick={handleOpen} >+ Create Album</div>
        </div><div><img src={editbutton} alt="Girl in a jacket"/></div>
        <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
        </div>
        </div> */}
      </div>


      <div style={{ display: 'flex', alignItems: "flex-start" }}>
        <div className="content-div-contacts" >
          <div className="content-grid-contacts">
            <div className="songs-flex-box-contacts">
              {items
                ? items.slice(first, first + rows).map((item) => (
                  <div className="songs-item-contacts"  key={item.id} style={{display:'flex'}}>
                    <div className="songs-container" style={{width:'80%',height:'100%'}} onClick={() => handlePlayPause(item.song, item.song_link, item.id, item.image)}>
                    <img src={songimg} className="song-image-contacts" alt="Girl in a jacket" />
                    <div className="song-name-contacts">{item.song}</div>
                    <div className="artist-name-contacts">{item.singers}</div>
                    </div>

                    {/* <div className="time-tag-contacts">5:32</div> */}
                    {/* {isPlaying && currentSong === item.song ? (
                  <FaPause className="play-icon-album" onClick={() => handlePlayPause(item.song)} />
                ) : (
                  <FaPlay className="play-icon-album" onClick={() => handlePlayPause(item.song)} />
                )} */}
                    <div className="tag-div-albums">
                      <img src={playButton} className="album-icon-albums" alt="Girl in a jacket" />
                      <div className="album-text-albums" style={{}}>{item.playcount}</div>
                      <img src={deletebutton} onClick={() => confirm2(item.id)} className="settings-icon-contacts" alt="Girl in a jacket" />
                      {/* <img src={playButton} onClick={() => handlePlayPause(item.song, item.song_link, item.id, item.image)} className="play-icon-contacts" alt="Girl in a jacket" /> */}
                    </div>

                  </div>)) : <p className="text-new-contacts">No songs available</p>}


              {items ? <Paginator first={first} rows={rows} totalRecords={items.length} onPageChange={(e) => {
                setfirst(e.first);
                setrows(e.rows);
              }}
                style={{
                  // Add your styles here
                  // For example:
                  width: 'auto',
                  fontSize: '12px',

                  // ... other styles
                }} /> : <p></p>}


            </div>
            {/* <div className="music-player-contacts">
              <div className="player-img-contacts">

                </div>
                <img src={lefticon} className="player-left-contacts" alt="Girl in a jacket"/>
                <img src={playermiddle} className="player-middle-contacts" alt="Girl in a jacket"/>
                <img src={playerright} className="player-right-contacts" alt="Girl in a jacket"/>
              
                </div> */}
          </div>
        </div>
        {/*       
       {currentSong && (
        <ReactAudioPlayer
          src={currentSong.song_link}
          autoPlay={isplaying}
          controls
        />
      )}
         */}

        <Toast ref={toast} />
        <ConfirmDialog />
        <div>
          {currentSong && (
            <Player
              audioElem={audioElem.current}
              isplaying={isPlaying}
              setisplaying={setIsPlaying}
              currentSong={currentSong}
              setCurrentSong={setCurrentSong}
              songs={items}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default Contacts;
