import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import { InputSwitch } from 'primereact/inputswitch';


import backbutton from "../../Assets/backbutton.svg";
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import closeicon from "./closeicon.svg";
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import React, { useState, useRef, useEffect } from 'react';

import { ProgressBar } from 'primereact/progressbar';

import { songsdata } from "../Player/audios";
import deletebutton from "./deletebutton.svg";
import { urlchange } from "../../Globalvalues";
import { useLocation } from "react-router-dom";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';

import { ProgressSpinner } from 'primereact/progressspinner';

import { TabView, TabPanel } from 'primereact/tabview';
import styles from './superalbum.module.css';
import { InputText } from 'primereact/inputtext';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Badge } from 'primereact/badge';

const Team = () => {

    const navigate = useNavigate();
    let username = sessionStorage.getItem('user');

    useEffect(() => {
        if (username === '' || username === null) {
            navigate('/')
        }
    }, [])

    const toast = useRef(null);
    const [opencreate, setOpencreate] = React.useState(false);
    const handleOpen = () => setOpencreate(true);
    const handleClose = () => {
        setname('');
        setemail('');
        setphoneno('');
        setpassword('');
        setOpencreate(false)
    }
    const [opencreate1, setOpencreate1] = React.useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [checked1, setChecked1] = useState(true);
    const [checked2, setChecked2] = useState(false);

    function refreshPage() {
        window.location.reload(true);
    }

    const handleOpen1 = () => {
        setOpencreate1(true);
        handleClose(false);
    }
    const showSuccess2 = () => {
        toast.current.show({ severity: 'error', summary: 'Success', detail: 'Artist Deleted Successfully', life: 3000 });
    }
    // const handleClose1 = () => setOpencreate1(false);
    // const [opencreate2, setOpencreate2] = React.useState(false);
    // const handleOpen2 = () => {
    //     setOpencreate2(true);
    //     setOpencreate1(false);
    // }
    // const handleClose2 = () => {
    //     setOpencreate2(false);

    // }
    // const handleopen3 = () => {
    //     setOpencreate2(false);
    //     setOpencreate3(true);
    // }
    // const [opencreate3, setOpencreate3] = React.useState(false);
    // const handleClose3 = () => setOpencreate3(false);

    const [artist, setartist] = useState();
    const token = sessionStorage.getItem("token");
    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [phoneno, setphoneno] = useState('');
    const [upnumber, setupnumber] = useState();


    // const customers = [
    //     {
    //         id: 1000,
    //         name: 'James Butt1',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10001,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10002,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10003,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },

    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },

    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },


    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },
    //     {
    //         id: 10004,
    //         name: 'James Butt',
    //         country: {
    //             name: 'Algeria',
    //             code: 'dz'
    //         },
    //         company: 'Benton, John B Jr',
    //         date: '2015-09-13',
    //         status: 'unqualified',
    //         verified: true,
    //         activity: 17,
    //         representative: {
    //             name: 'Ioni Bowcher',
    //             image: 'ionibowcher.png'
    //         },
    //         balance: 70663
    //     },

    // ]

    const accept = (id) => {

        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        deletecollectible(id);
    }
    const confirm2 = (id) => {
        console.log(id);
        confirmDialog({
            message: 'Do you want to delete this Artist?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(id),
        });
    };

    const handleNumberChange = (e) => {
        const inputValue = e.target.value;

        // Ensure that the input is a number
        if (!isNaN(inputValue)) {
            // Limit the length to a specific number (e.g., 10 for a mobile number)

            const maxLength = 10;
            const truncatedValue = inputValue.slice(0, maxLength);
            // Update the state with the sanitized and truncated value
            setupnumber(truncatedValue);
        }
        else {
            alert("Enter Valid Mobile Number!")
        }
    }

    const deletecollectible = async (id) => {

        // console.log(file)
        const requestOptions1 = {
            method: "DELETE",
            headers: { "Authorization": `Bearer ${token}` },
        }

        const response1 = await fetch(`${urlchange}/deleted_artist?artistid=${id}`, requestOptions1);

        const data1 = await response1.json();
        if (!response1.ok) {
            console.log(data1);

        }
        else {
            refreshPage();
            showSuccess2();
        }
    }


    const handleToggle = (id) => {
        // setChecked1(!checked1)
        toggleSatus(id)
        console.log(id);
    }

    const toggleSatus = async (id) => {
        const requestOptions1 = {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` }

        }
        const response = await fetch(`${urlchange}/active_inactive_artist?userid=${id}`, requestOptions1);
        const data = await response.json();
        if (!response.ok) {

            console.log(data);
            showSuccess12(data.message);

        }
        else {
            console.log(data)
            showSuccess(data.message);
            getartist();
        }
    }

    const showSuccess = (msg) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
    }

    const showSuccess12 = (msg) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
    }
    const artsitdata =
    {
        "fullname": name,
        "password": password,
        "email": email,
        "phoneno": phoneno,
        "profile_image": ""
    }

    const displaydata = (id) => {

        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        console.log(id)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 345,
        bgcolor: 'white',
        // border: '2px solid #000',
        borderRadius: '0.78rem',
        backgroundColor: "#181A22",
        border: '2px solid #FAFAFA'
    }

    const getartist = async () => {
        const requestOptions1 = {
            method: "GET",
            headers: { "Authorization": `Bearer ${token}` }

        }
        const response = await fetch(`${urlchange}/get_artist`, requestOptions1);
        const data = await response.json();

        if (!response.ok) {
            console.log("no");
            setDataLoaded(true);
        }

        else {
            console.log(data.data)
            setartist(data.data);
            setDataLoaded(true);
            setname('');
            setemail('');
            setphoneno('');
            setpassword('');
        }
    }

    const handlePhoneNoChange = (e) => {
        // Remove non-numeric characters

        const numericValue = e.target.value.replace(/\D/g, '');

        if (!isNaN(numericValue)) {
            // Limit to 10 characters
            const truncatedValue = numericValue.slice(0, 10);

            // Update the state
            setphoneno(truncatedValue);
        }
        else {
            alert("Enter Valid Mobile Number!")
        }
        
    };

    const handleuploadcheck = () => {
        // Check if the song path is empty or not selected
        if (!name.trim()) {
            alert('Please fill all the details.');
            return; // Prevent further execution
        }
        if (!email.trim()) {
            alert('Please fill all the details.');
            return; // Prevent further execution
        }
        if (!phoneno.trim()) {
            alert('Please fill all the details.');
            return; // Prevent further execution
        }
        if (!password.trim()) {
            alert('Please fill all the details.');
            return; // Prevent further execution
        }

        // Check if the singer name is empty
        // setsonguploaded(false);
        createartist();
    };


    const createartist = async () => {

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(
                artsitdata
            ),
        };

        const response = await fetch(`${urlchange}/create_user`, requestOptions);
        const data = await response.json();
        if (!response.ok) {
            console.log(data.message);
            showSuccess12(data.message);
            setDataLoaded(true);
        }
        else {

            showSuccess(data.message);
            handleClose();
            getartist();
            setDataLoaded(true);
        }
    }

    useEffect(() => {

        getartist();
    }, []);

    if (!dataLoaded) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh', backgroundColor: '#000000' }}><ProgressSpinner /></div>;
    }

    return (
        <Box style={{ overflow: "auto", height: "92.70vh", backgroundColor: '#000000' }} >
            <div className={styles.main_header_text_grid}>
                <div className={styles.main_haeder_text}> Artists</div>
                <div onClick={handleOpen} className={styles.main_header_create_button}>+ Artist</div>
            </div>
            <div className={styles.table_flex_main}>
                <div style={{ width: '90%' }}>
                    <DataTable value={artist} sortOrder={1} sortField="username" paginator rows={10} tableStyle={{ width: '100%' }} rowClassName={styles.custom_datatable_row}>
                        <Column field="username" header="Name" headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA' }} style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                        <Column field="email" headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA' }} header="Email" style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                        <Column field="phoneno" headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA' }} header="Phoneno" style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                        {/* <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Albums" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Webpages" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Collectibles" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
                <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Songs" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column> */}
                        <Column headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA' }} header="Status" style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}
                            body={(rowData) =>
                            (
                                rowData.active
                                    ? <InputSwitch style={{ height: '24px' }} checked={checked1} onChange={() => handleToggle(rowData.userid)} />
                                    : <InputSwitch style={{ height: '24px' }} checked={checked2} onChange={() => handleToggle(rowData.userid)} />
                            )}
                        />
                        <Column headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA' }} header="Delete" style={{ width: '25%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}
                            body={(rowData) => (
                                <img style={{ cursor: 'pointer' }} src={deletebutton} onClick={() => confirm2(rowData.userid)} />
                            )}></Column>
                    </DataTable>
                </div>
            </div>

            <Modal
                open={opencreate}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className={styles.modal_header_albums}>
                        <div className={styles.modal_header_text}>Create Artist</div>
                        <img src={closeicon} onClick={handleClose} alt="img" className={styles.modal_close_icon} />
                        <div className={styles.modal_input_label}>Enter Artist Details</div>
                        <input type="text" id="lname" autocomplete="off" className={styles.modal_input_artistname1} maxLength={30} placeholder="Enter Name" name="lname" onChange={(e) => setname(e.target.value)} />
                        <input type="email" id="lname" autocomplete="off" className={styles.modal_input_artistname2} maxLength={40} placeholder="Enter Email" name="lname" onChange={(e) => setemail(e.target.value)} />
                        <input type="tel" id="lname" autocomplete="off" className={styles.modal_input_artistname3} maxLength={10} placeholder="Enter Ph.No" name="lname" onChange={handlePhoneNoChange} />
                        <input type="text" id="lname" autocomplete="off" className={styles.modal_input_artistname4} maxLength={20} placeholder="Enter Password" name="lname" onChange={(e) => setpassword(e.target.value)} />
                        <button type="button" className={styles.modal_input_nextbutton} onClick={handleuploadcheck} >Create</button>
                        <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose}>Cancel</button>
                    </div>
                </Box>
            </Modal>

            {/* <Modal
        open={opencreate1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Email Address</div>
     <input type="text"  id="lname" className={styles.modal_input_artistname} name="lname" onChange={(e) => setemail(e.target.value)} />
     <button type="button" className={styles.modal_input_nextbutton} onClick={handleOpen2}>Next</button>
     <button type="button" className={styles.modal_input_cancelbutton}  onClick={handleClose1}>Cancel</button>
     </div>
        </Box>
      </Modal> */}
            {/* <Modal
        open={opencreate2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Phone Number</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname" onChange={(e) => setphoneno(e.target.value)}/>
     <button type="button" className={styles.modal_input_nextbutton} onClick={handleopen3} >Next</button>
     <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose2}>Cancel</button>
     </div>
        </Box>
      </Modal> */}
            {/* <Modal
        open={opencreate3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Password</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname" onChange={(e) => setpassword(e.target.value)}/>
     <button type="button" className={styles.modal_input_nextbutton} onClick={createartist} >Create</button>
     <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose3}>Cancel</button>
     </div>
        </Box>
      </Modal> */}
            <ConfirmDialog />

            <Toast ref={toast} />
        </Box>
    );
};

export default Team;
