import React, {useState} from "react";


function Pagination({postperpage,totalposts,paginate}){
    const pagenumbers=[];
    for (let i=1;i<=Math.ceil(totalposts/postperpage);i++){
        pagenumbers.push(i);
    }

    const[activepage,setacivepage]=useState(1);

const handlepageclick=(number)=>{
    setacivepage(number);
}
return(
    <div>
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                {
                    pagenumbers.map((number)=>(
                        <li class="page-item" key={number}>
                        <a  class="page-link"
                      
                        onClick={
                            ()=>{
                                handlepageclick(number);
                                paginate(number);
                            }
                        }
                        
                        >{number}</a></li>
                    ))
                }
            </ul>
        </nav>
    </div>
)
            }
            export default Pagination;