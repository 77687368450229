import { Box, Button, IconButton, Typography, useTheme, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import WebIcon from '@mui/icons-material/Web';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import albumns from "./Albumnss.svg";
import Albumnss1 from "./Albumnss1.svg";

import styles from './Superdash.module.css';
import { Dropdown } from 'primereact/dropdown';
import favicon from '../../Assets/favicon.jpg'

import React, { useState, useRef, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { urlchange } from "../../Globalvalues";
import collectibleimg from "./col.svg";
import artistimg from "./artisttot.svg";
import webtot from "./webtot.svg";
import webtot1 from "./webtot1.svg";
import Collectible1 from '../../Assets/Collectible1.svg'
import Collectible11 from '../../Assets/Collectible11.svg'

import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const token = sessionStorage.getItem("token");
  const theme = useTheme();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [product, setproduct] = useState('');
  const [artist, setArtist] = useState('');
  const [webpage, setwebpage] = useState('');
  const [collectible, setcollectible] = useState('');
  const [album, setAlbum] = useState('')
  const colors = tokens(theme.palette.mode);

  const [artistInfo, setArtistInfo] = useState(false);
  const [artistName, setArtistName] = useState('')
  const [artistUsername, setArtistUsername] = useState('')
  const [artistEmail, setArtistEmail] = useState('')
  const [artistImage, setArtistImage] = useState('')


  const [artistAlbumCount, setartistAlbumCount] = useState('')
  const [artistWebpageCount, setArtistWebpageCount] = useState('')
  const [artistTap, setArtistTap] = useState('')
  const [artistPlayCount, setArtistPlayCount] = useState('')
  const [artistCollectibleCount, setArtistCollectibleCount] = useState('')
  const [invalid, setInvalid] = useState(false);

  const [topSongsData, setTopSongsData] = useState([]);

  const getalbum = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/superadmin_dashboard`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {

      console.log("no");

    }


    else {
      // console.log(data)
      setArtist(data.data.total_active_artist);

      //  var albumcount=data.album_count;
      setcollectible(data.data.total_collectibles);
      setproduct(data.data.product_count);
      setwebpage(data.data.webpage_count);
      setAlbum(data.data.album_count);

      setDataLoaded(true);
    }
  }
  useEffect(() => {

    getalbum();
  }, []);

  // if (!dataLoaded) {
  //   return <div className="spinner-album" >    <ProgressSpinner /></div>;
  // }

  const getArtist = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_artist`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {

      console.log("no");

    }


    else {
      console.log(data)
      setArtistName(data.data);
      setDataLoaded(true);
    }
  }
  useEffect(() => {

    getArtist();
  }, []);

  const handleArtistDetails = (e) => {
    setSelectedCity(e.value)
    // console.log(selectedCity);
    const demo = e.value.userid
    getArtistInfo(demo)
    topsongs(demo)
  }

  const filterArtists = (event) => {
    const query = event.value;
    const filteredArtists = artistName.filter((artist) =>
      artist.username.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredArtistName(filteredArtists);
  }

  const getArtistInfo = async (id) => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/artist_dashboard?artistid=${id}`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log("no");
    }

    else {
      console.log(data)
      setartistAlbumCount(data.data.album_count);
      setArtistWebpageCount(data.data.total_webpage);
      setArtistTap(data.data.total_tap);
      setArtistPlayCount(data.data.total_play_count);
      setArtistCollectibleCount(data.data.collectibles_count)
      setArtistUsername(data.data.username)
      setArtistEmail(data.data.email)
      setArtistImage(data.data.image)
      setArtistInfo(true)
    }
  }
  useEffect(() => {

    getArtistInfo();
  }, []);

  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredArtistName, setFilteredArtistName] = useState([]);
  const [selectArtist, setSelectArtist] = useState();
  const cities = [
    { name: 'New York ', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];

  const dropdownStyle = {
    width: '100%',
    backgroundColor:"#292D34",
    overflowX: 'hidden',
  };

  const topsongs = async (id) => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/top-songs-artist?artistid=${id}`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log("no");
      setInvalid(true)
    }

    else {
      console.log(data)
      setInvalid(false)
      // setAlbumName(data.data);
      setTopSongsData(data.data);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    topsongs();
  }, []);

  console.log(selectedCity);
  return (
    <Box sx={{ overflowY: 'auto', height: '92.8vh',backgroundColor:'#0b0d0f' }}>
      <div className={styles.header_grid_dash}>
        <div className={styles.header_text_dash}>Dashboard</div>
        {/* <div className="icons-grid-header">
          <div className="button-div-header">
            <div className="button-text-div" onClick={navigatetoadd}>+ Create Album</div>
            </div><div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
            </div>
            </div> */}

      </div>

      <div className={styles.content_div_flex}>
        <div className={styles.content_div_grid_items}>
          <img src={artistimg} alt="alt img" className={styles.content_div_grid_items_img} />
          <div className={styles.content_div_grid_items_text}>Total Artists</div>
          <div className={styles.content_div_grid_items_count}>{artist}</div>
        </div>
        <div className={styles.content_div_grid_items}>
          <div className={styles.content_div_grid_items_text}>Albums</div>
          <img src={albumns} alt="alt img" className={styles.content_div_grid_items_img} />
          <div className={styles.content_div_grid_items_count}>{album}</div></div>
        <div className={styles.content_div_grid_items}>
          <div className={styles.content_div_grid_items_text}>Collectibles</div>
          <img src={Collectible1} alt="alt img" className={styles.content_div_grid_items_img} />
          <div className={styles.content_div_grid_items_count}>{collectible}</div></div>
        <div className={styles.content_div_grid_items}>
          <div className={styles.content_div_grid_items_text}>Webpages</div>
          <img src={webtot} alt="alt img" className={styles.content_div_grid_items_img} />
          <div className={styles.content_div_grid_items_count}>{webpage}</div></div>
      </div>

      <div className={styles.content_div_flex1}>
        <div style={{ width: "49.2%" }}>
          <Dropdown style={dropdownStyle} value={selectedCity} onChange={handleArtistDetails} options={artistName} optionLabel="username"
            editable={false} filter filterPlaceholder="Search Artist" onFilter={filterArtists} className="white-text" placeholder="Select Artist" />
        </div>

        {artistInfo ?
          <div style={{ display: 'flex', gap: '1.5rem' }}>
            <div style={{ width: "50%", backgroundColor: "#292D34", paddingTop: '0.58rem', paddingBottom: '0', borderRadius: '10px', marginTop: '1.5rem' }}>
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex", margin: "auto", flexDirection: "column" }}>
                <img style={{ height: '6.5rem', width: '6.5rem', marginBottom: '6px',marginTop:'1rem', borderRadius: '8px' }} alt="UserImage" src={artistImage || favicon} />
                <h4 style={{ marginBottom: "1px", color: "#FAFAFA" }}>{artistUsername}</h4>
                {/* <p style={{ color: "black" }}>{artistEmail}</p> */}
              </div>
              <div className={styles.content_div_flex}>
                <div className={styles.content_div_grid_itemsdrop}>
                  <div className={styles.content_div_grid_items_textdrop}>Albums</div>
                  <img src={Albumnss1} alt="alt img" className={styles.content_div_grid_items_imgdrop} />
                  <div className={styles.content_div_grid_items_countdrop}>{artistAlbumCount}</div></div>
                <div className={styles.content_div_grid_itemsdrop}>
                  <div className={styles.content_div_grid_items_textdrop}>Webpages</div>
                  <img src={webtot1} alt="alt img" className={styles.content_div_grid_items_imgdrop} />
                  <div className={styles.content_div_grid_items_countdrop}>{artistWebpageCount}</div></div>
              </div>
              <div className={styles.content_div_flex}>
                <div className={styles.content_div_grid_itemsdrop}>
                  <div className={styles.content_div_grid_items_textdrop}>Total Taps</div>
                  <img src={collectibleimg} alt="alt img" className={styles.content_div_grid_items_imgdrop} />
                  <div className={styles.content_div_grid_items_countdrop}>{artistTap}</div></div>
                <div className={styles.content_div_grid_itemsdrop}>
                  <div className={styles.content_div_grid_items_textdrop}>Collectibles</div>
                  <img src={Collectible11} alt="alt img" className={styles.content_div_grid_items_imgdrop} />
                  <div className={styles.content_div_grid_items_countdrop}>{artistCollectibleCount}</div></div>
              </div>
              <div className={styles.content_div_flex}>
                <div className={styles.content_div_grid_items1}>
                  <div className={styles.content_div_grid_items_textdrop}>Total Play Count</div>
                  <img src={collectibleimg} alt="alt img" className={styles.content_div_grid_items_imgdrop} />
                  <div className={styles.content_div_grid_items_countdrop}>{artistPlayCount}</div></div>
              </div>
            </div>
            <div style={{ paddingBottom: "3rem", backgroundColor: "#181A22", marginTop: '1.5rem', borderRadius: '0.8rem', width: '50%',height:'50%' }}>
              {!invalid ?
              <>
              <p style={{ color: "white", paddingTop: "1.5rem", fontWeight: "normal", fontSize: '14px', paddingLeft: '1rem', paddingBottom: "0.5rem" }}>Top Played Songs</p>   
              <table style={{ color: 'black' }} className="table-list">
                <thead>
                  <tr>
                    <th style={{ padding: "12px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto',color:'white', fontWeight: 'normal' }}>Album Name</th>
                    <th style={{ padding: "12px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto',color:'white', fontWeight: 'normal' }}>Song Name</th>
                    <th style={{ padding: "12px", backgroundColor: "#0B0D0F", fontSize: '13px', fontFamily: 'Roboto',color:'white', fontWeight: 'normal' }}>Play Count</th>
                    {/* Add more header columns as needed */}
                  </tr>
                </thead>
                <tbody style={{ color: "black" }}>
                  {topSongsData.map((song, index) => (
                    <tr key={index}>
                      <td style={{padding: "12px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: 'white', fontWeight: 'normal' }}>{song.albumname}</td>
                      <td style={{padding: "12px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: 'white', fontWeight: 'normal' }}>{song.song}</td>
                      <td style={{padding: "12px", backgroundColor: "#181A22", fontSize: '12px', fontFamily: 'Roboto', color: 'white', fontWeight: 'normal' }}>{song.playcount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </>
              :<p style={{color:'#7D8FA5',display:'flex',justifyContent:'center',alignItems:'center',height:'33.5rem',fontSize:'25px',fontWeight:'bold',padding:'15px'}}>Songs Not Available for the Particular Album</p>}
            </div>
          </div>
          : null}
      </div>

    </Box>
  );
};

export default Dashboard;
