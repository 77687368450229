import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import './album.css';
import editbutton from "./editbutton.svg";
import deletebutton from "./deletebutton.svg";
import arrow from "./arrow.svg";
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import albumicon from "./albumicon1.svg";
import songimg from "./songimg.png";
import settings from "./settings.svg";
import lefticon from "./lefticon.svg"
import playermiddle from "./playermiddle.svg";
import playerright from "./playerright.svg";
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import React, { useState, useRef, useEffect } from 'react';
import { SpeedDial } from 'primereact/speeddial';
import imagepreview from "./K.jpg";
import { useLocation } from "react-router-dom";
import Player from "../Player/player";
import uploadicon from "./uploadicon.svg";

import { ProgressBar } from 'primereact/progressbar';

import { songsdata } from "../Player/audios";

import { urlchange } from "../../Globalvalues";

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';

import { ProgressSpinner } from 'primereact/progressspinner';
import addicon from "./add icon.svg";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Team = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const token = sessionStorage.getItem("token");
  function refreshPage() {
    window.location.reload(true);
  }
  const [imagePreview, setImagePreview] = useState(null);
  const [open123, setOpen123] = useState(false);
  const handleClose123 = () => setOpen123(false);
  const handleopen123 = () => setOpen123(true);
  // const [items, setitems] = useState(null);
  const [file, setFile] = useState(null);
  const [items, setitems] = useState();
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setalbumname('');
    setOpen(false);
  };
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);
  const handleopen2 = () => { setOpen2(true) };
  const [albumname, setalbumname] = useState('');
  const [backupimg, setbackupimg] = useState('');
  const [delid, setdelid] = useState('');
  var [path1, setpath1] = useState();
  var [updatepath, setupdatepath] = useState();
  var [songpath, setsongpath] = useState();
  var [imgurl, setimgurl] = useState('');
  const [albumupname, setalbumupname] = useState('');
  const [uploadid, setuploadid] = useState();
  const [albumupid, setalbumupid] = useState();
  const [singername, setsingername] = useState('');
  const [example, setexample] = useState(false);
  const handleOpen1 = () => {
    setOpen(false);
    setOpen1(true);
  }
  const handleClose1 = () => {
    setpath1('');
    setalbumname('');
    setOpen1(false);
  };
  const [dataLoaded, setDataLoaded] = useState(false);
  const [songuploaded, setsonguploaded] = useState(true);
  const showSuccess = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess12 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
  }
  const accept = (id) => {

    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    deletealbum(id);
  }

  const reject = () => {
    console.log("no");
  }
  const confirm2 = (id) => {
    // setdelid(id);
    confirmDialog({
      message: 'Do you want to delete this Album?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      className: 'custom-dialog',
      // footer: (
      //   <div>
      //     <Button label="No" icon="pi pi-times" onClick={reject} className="custom-no-button" />
      //     <Button label="Yes" icon="pi pi-check" onClick={accept} className="custom-yes-button" />
      //   </div>
      // ),
    });
  };



  const showSuccess1 = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
  }
  const showSuccess11 = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
  const showSuccess2 = () => {
    toast.current.show({ severity: 'error', summary: 'Success', detail: 'Album deleted', life: 3000 });
  }
  // const showstopmsg = () => {
  //   toast.current.show({severity:'error', summary: 'Success', detail:'Album name required', life: 3000});
  // }


  const [first, setfirst] = useState(0);

  const [rows, setrows] = useState(5);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 220,
    bgcolor: 'white',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    backgroundColor: '#181A22',
    border: "2px solid #FAFAFA"
  };
  const style1234 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 400,
    bgcolor: 'white',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    backgroundColor: '#181A22',
    border: '2px solid #FAFAFA'

  };

  const handleFileCreate = (event) => {
    // const selectedFile = e.target.files[0];

    // if (selectedFile) {
    //   // Check if the selected file type is an image
    //   if (!selectedFile.type.startsWith('image/') && selectedFile.type === 'image/svg+xml') {
    //     // If not an image, show an alert or perform any other error handling
    //     alert('Please select a valid image file.');
    //     // Reset the input field
    //     e.target.value = null;
    //   } else {
    //     // Proceed with handling the image file
    //     setpath1(selectedFile);
    //   }
    // }

    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file type is an image (excluding SVG)
      if (file.type.startsWith('image/') && !file.name.toLowerCase().endsWith('.svg')) {
        setpath1(file);
      } else {
        alert('Please select a valid image file (excluding SVG).');
        // Clear the input field
        event.target.value = null;
        setpath1(null);
      }
    }
  };

  const handleNextClick = () => {
    // Check if the album name is empty
    if (!albumname.trim()) {
      // Show an error message (you can replace this with your own logic)
      alert('Please enter an album name.');
      return;
      // showstopmsg();
      // return;
    }

    // Continue with the next step or action
    // For example, you can call handleOpen1() here
    handleOpen1();
  };
  const handleuploadcheck = () => {
    // Check if the song path is empty or not selected
    if (!singername.trim()) {
      alert('Please enter the artist name.');
      return; // Prevent further execution
    }
    if (!songpath) {
      alert('Please select a song file.');
      return; // Prevent further execution
    }

    // Check if the singer name is empty
    setsonguploaded(false);
    uploadsongs();
  };
  const handlecreatealbum = () => {
    // Check if the song path is empty or not selected
    if (!path1) {
      alert('Please upload a image.');
    } else {
      setOpen1(false);
      confirmAlert({
        title: 'Confirmation',
        message: 'Do you want to confirm this as Album Name?',
        style: {
          // Add styles for the "Yes" button
          background: 'green', // Change background color to green
          color: 'white', // Change text color to white
          // Add any other styles you need
        },
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              // User clicked "Yes"
              console.log('Confirmed!');
              setsonguploaded(false);
              createstateuser();
            },
            style: {
              // Add styles for the "Yes" button
              background: 'green', // Change background color to green
              color: 'white', // Change text color to white
              // Add any other styles you need
            },
          },
          {
            label: 'No',
            onClick: () => {
              // User clicked "No" or closed the dialog
              console.log('Cancelled!');
            },
            style: {
              // Add styles for the "No" button
              background: 'red', // Change background color to red
              color: 'white', // Change text color to white
              // Add any other styles you need
            },
          },
        ],
      });
    }
    // Check if the singer name is empty
  };

  // const modal = document.querySelector('.react-confirm-alert');
  // const title = modal.querySelector('.react-confirm-alert-title');
  // const message = modal.querySelector('.react-confirm-alert-message');

  // if (modal && title) {
  //   modal.style.backgroundColor = 'blue';
  //   title.style.color = 'white';
  // }


  const uploadeimg = async () => {
    const formData = new FormData()

    formData.append(
      'image',
      path1
      // path1.name,
    )
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": "Bearer" + token },
      body:
        formData

    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);

    const data1 = await response1.json();

    //  setimgurl(data1.imageurl)

    //  console.log(ref4.current)


    //   const response = await fetch(`http://13.234.238.198:8000/update_content/${id}`, requestOptions);
    //   const data = await response.json();
    if (!response1.ok) {
      // showErrorNotification("Cannot upload image");
      console.log(path1)
    }
    else {
      // showSuccessNotification("image added successfully");

      console.log(data1)
      console.log(path1)

    }





  }





  const handleid = (id) => {
    setuploadid(id);
    handleopen2();
  }

  function navigatetoadd(id) {

    navigate('/songs', { state: { id } });
  }


  const onUpload = (e) => {
    // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });

    uploadsongs();
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Display image preview
    if (selectedFile) {
      const previewURL = URL.createObjectURL(selectedFile);
      setImagePreview(previewURL);
    }

    // Set the selected file
    setupdatepath(selectedFile);
  };
  const createstateuser = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'image',
      path1
      // path1.name,
    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": "Bearer" + { token } },
      body:
        formData
    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);
    const data1 = await response1.json();
    const image_ex = data1.image_url;
    console.log(image_ex);

    var datauser =
    {
      "albumname": albumname,
      "image": image_ex
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        datauser
      ),
    };

    const response = await fetch(`${urlchange}/create_album`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess12(data.message)
      setsonguploaded(false)
    }
    else {
      showSuccess(data.message);
      setsonguploaded(false)
      handleClose1();
      getalbum();
      refreshPage();
    }
  }

  const checkandupdate = () => {

    if (updatepath instanceof File) {
      console.log("The value is null");
      updatealbum();
    } else {
      updatealbum1();
    }
  }

  const updatealbum1 = async () => {

    var updateuser =
    {
      "albumname": albumupname,
      "image": backupimg
    }

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser

      ),
    };

    const response = await fetch(`${urlchange}/update_album?album_id=${albumupid}`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess12(data.message)
    }
    else {

      showSuccess(data.message);
      handleClose123();
      getalbum();

    }
  }


  const updatealbum = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'image',
      updatepath
      // path1.name,
    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": "Bearer" + { token } },
      body:
        formData
    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);

    const data1 = await response1.json();
    const image_ex = data1.image_url;
    console.log(image_ex);


    var updateuser =
    {
      "albumname": albumupname,
      "image": image_ex
    }


    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser

      ),
    };

    const response = await fetch(`${urlchange}/update_album?album_id=${albumupid}`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess12(data.message)
    }
    else {

      showSuccess(data.message);
      handleClose123();
      getalbum();

    }
  }
  const getalbum = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/album`, requestOptions1);
    const data = await response.json();

    if (!response.ok) {
      console.log("no");
      setDataLoaded(true);
    }

    else {
      console.log(data.data)
      setitems(data.data);
      setDataLoaded(true);
      setalbumname('');
      setpath1('');
    }
  }

  const uploadsongs = async () => {

    const formData = new FormData()
    // console.log(file)

    formData.append('file', songpath);
    formData.append('albumid', uploadid);
    formData.append('singers', singername)
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": `Bearer ${token}` },
      body:
        formData

    }

    const response1 = await fetch(`${urlchange}/add_song`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      setsonguploaded(true);
      console.log(data1);
      // setsonguploaded(true);
      showSuccess11(data1.message);
      setsingername('');
      setsongpath('');
    }
    else {

      showSuccess1(data1.message);
      setsonguploaded(true);
      //  setsonguploaded(true);
      handleClose2();
      // setsingername('');
      // setsongpath('');
      refreshPage();
    }





  }
  const deletealbum = async (id) => {

    // console.log(file)


    const requestOptions1 = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${token}` },


    }

    const response1 = await fetch(`${urlchange}/deleted_album?album_id=${id}`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      console.log(data1);
    }
    else {

      showSuccess2();

      // getalbum();
      refreshPage();
    }





  }
  const handleupdatealbum = (id) => {
    setalbumupid(id.id);
    setalbumupname(id.albumname)
    setImagePreview(id.image)
    setbackupimg(id.image)
    handleopen123();
  };

  useEffect(() => {

    getalbum();
  }, []);

  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh', backgroundColor: '#000000' }}>    <ProgressSpinner /></div>;
  }
  // if (!songuploaded) {
  //   return   <div className="card">
  //   <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
  // </div>



  return (

    <Box sx={{ overflowY: 'auto', height: '92.8vh', backgroundColor: '#0b0d0f' }}>
      <div className="header-grid-albums">
        <div className="header-text-albums">Albums</div>
        <div className="icons-grid-header-albums">
          <Button label="+ Album" className="button-div-header-albums" onClick={handleOpen} severity="success" />
          {/* <div className="button-div-header-albums">
            <div className="button-text-div-albums" onClick={handleOpen} >+ Create Album</div>
            </div> */}
          {/* <div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
            </div> */}
        </div>
      </div>
      <div className="content-div-albums" >
        <div className="content-grid-albums">
          <div className="album-flex-box-albums">


            {items
              ? items.slice(first, first + rows).map((item) => (<div key={item.id} className="album-item-albums">
                <div className="album-name-div-albums">{item.albumname}</div>
                <div className="album-date-div-albums">{item.created_on}</div>
                <div className="tag-div-albums1">
                  <img src={albumicon} className="album-icon-albums1" alt="Girl in a jacket" />
                  <div className="album-text-albums1">{item.number_of_songs}</div>
                </div>
                <img src={addicon} className="settings-icon-albums-album" onClick={() => handleid(item.id)} alt="Girl in a jacket" />
                <img src={editbutton} onClick={() => handleupdatealbum(item)} className="edit-icon-display-album" alt="Girl in a jacket" />
                {/* <img src={deletebutton} onClick={() =>deletealbum(item.id)} className="delete-icon-new-album" alt="Girl in a jacket"/> */}
                {/* <img src={deletebutton} onClick={() =>deletealbum(item.id)} className="delete-icon-new-album" alt="Girl in a jacket"/> */}
                <img src={deletebutton} onClick={() => confirm2(item.id)} className="delete-icon-new-album" alt="Girl in a jacket" />
                <img src={arrow} onClick={() => navigatetoadd(item.id)} className="arrow-icon-albumns" alt="Girl in a jacket" />
                {/* <img src={editbutton} className="add-icon-display-album" alt="Girl in a jacket"/> */}
                {/* <SpeedDial model={items1} className="settings-icon-albums-album" radius={80} type="circle" buttonClassName="p-button-warning" /> */}
              </div>))
              : <p className="colour-text-album">No Albums available</p>}

            {items ? <Paginator first={first} rows={rows} totalRecords={items.length} onPageChange={(e) => {
              setfirst(e.first);
              setrows(e.rows);
            }} /> : <p></p>}
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="modal-header-albums">
            <div className="modal-header-text-albums">Create Album</div>
          </div>
          <div className="modal-input-name-albums">Enter Album Name</div>
          <input type="text" autoComplete='off' className="modal-input-field-albums" id="fname" maxLength={30} onChange={(e) => setalbumname(e.target.value)} name="fname" />
          <button className="modal-button-next-albums" onClick={handleNextClick}>Next</button>
          <button className="modal-button-next2-albums" onClick={handleClose} >Cancel</button>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {songuploaded ? (<div>
            <div className="modal-header-albums">
              <div className="modal-header-text-albums">Create Album</div>
            </div>
            <div className="modal-input-name1-albums-img">Upload Image</div>
            {/* <FileUpload mode="basic" name="demo[]" className="modal-upload-button" url="/api/upload" accept="image/*" maxFileSize={1000000} onUpload={onUpload} /> */}
            {/* <div className="modal-input-file1"></div> */}
            {/* {file ? (
        <img src={file} className="modal-input-upload-preview" alt="File Preview" />
      ) : (
        <img src={uploadicon} className="modal-input-upload" alt="Upload icon" />
      )} */}
            {/* <img src={uploadicon} className="modal-input-upload"  alt="Girl in a jacket"/> */}
            {/* <div className="modal-input-upload-text">Upload you picture
</div> */}
            {/* <input type="file" className="modal-input-file" id="lname"   onChange={handleFileChange} name="lname"/> */}
            <input type="file" id="lname" className="modal-input-upload-albums" onChange={(e) => handleFileCreate(e)} accept="image/*" name="lname" />
            {/* <Image src={imagepreview} className="modal-preview-img" width="150"  alt="Girl in a jacket"/> */}
            <button className="modal-button-next4-albums" onClick={handlecreatealbum}>Create</button>
            <button className="modal-button-next5-albums" onClick={handleClose1} >Cancel</button></div>) : (<div className="album-loader-spinner"  ><ProgressSpinner style={{ width: '40px', height: '40px' }} /></div>)}
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {songuploaded ? (<div>
            <div className="modal-header-albums">
              <div className="modal-header-text-albums">Upload Song</div>
            </div>
            <div className="modal-input-name1-albums-song">Upload Song</div>
            <input className="modal-album-songupload" type="file" id="formFile" onChange={(e) => setsongpath(e.target.files[0])} />
            <input className="modal-album-artisttext" maxLength={30} placeholder="Enter artist name" onChange={(e) => setsingername(e.target.value)} type="text" id="formFile" />
            <button className="modal-button-next1-upload" onClick={handleuploadcheck}>Upload</button>
            <button className="modal-button-next2-upload" onClick={handleClose2} >Cancel</button></div>) : (<div className="album-loader-spinner"  ><ProgressSpinner style={{ width: '40px', height: '40px' }} /></div>
          )}
        </Box>

      </Modal>
      <Modal
        open={open123}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1234}>
          <div className="modal-header-albums">
            <div className="modal-header-text-albums">Edit Albums</div>
          </div>
          <div className="modal-input-name1-albums-song">Edit Albums</div>
          <img src={imagePreview} alt="img" className="img-upload-preview-albums" />
          <input className="modal-album-imageupdate" type="file" id="formFile" onChange={handleFileChange} />
          <p style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%' }} className="modal-input-name1-albums-song1" >{albumupname}</p>
          <button className="modal-button-next1-update" onClick={checkandupdate}>Update</button>
          <button className="modal-button-next2-update" onClick={handleClose123} >Cancel</button>
        </Box>

      </Modal>
      <Toast ref={toast} />
      <ConfirmDialog />
      {/* <ConfirmPopup /> */}
    </Box>
  );
};

export default Team;
