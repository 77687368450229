import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import WebIcon from '@mui/icons-material/Web';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import placeholder from "./favicon.jpg";
import editprofile from "./Editprofile.svg"
import './account.css';
import Modal from '@mui/material/Modal';
import React, { useState, useRef, useEffect } from 'react';
import { urlchange } from "../../Globalvalues";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const toast = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [albumupname, setalbumupname] = useState('');
  const [upphoneno, setupphoneno] = useState('');
  const [name, setname] = useState();
  const [upname, setupname] = useState();
  const [upnumber, setupnumber] = useState();

  const [number, setnumber] = useState();
  const [role, setrole] = useState();
  const [img, setimg] = useState();
  const [albumupid, setalbumupid] = useState();
  const token = sessionStorage.getItem("token");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const username = localStorage.getItem('user');
  // const number = localStorage.getItem('number');
  // const role = localStorage.getItem('role');
  // const img = localStorage.getItem('img');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleopen = () => setOpen(true);
  var [updatepath, setupdatepath] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [backupimg, setbackupimg] = useState('');
  const [items, setitems] = useState();
  const checkandupdate = () => {

    if (upnumber.length === 10) {
      if (updatepath instanceof File) {
        console.log("The value is null");
        updatealbum();
      } else {
        updatealbum1();
      }
    }
    else {
      alert('Invalid Mobile Number!')
    }
  }

  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[a-zA-Z.\s]*$/.test(inputValue)) {
      setupname(inputValue);
    }
  };

  const handleNumberChange = (e) => {
    const inputValue = e.target.value;

    // Ensure that the input is a number
    if (!isNaN(inputValue)) {
      // Limit the length to a specific number (e.g., 10 for a mobile number)
      const maxLength = 10;
      const truncatedValue = inputValue.slice(0, maxLength);
      // Update the state with the sanitized and truncated value
      setupnumber(truncatedValue);
    }
    else {
      alert("Enter Valid Mobile Number!")
    }
  }

  const handleCancelClick = () => {
    // Revert the input value to the original value
    setupname(name);
    setupnumber(number);
    handleClose();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Display image preview
    if (selectedFile) {
      const previewURL = URL.createObjectURL(selectedFile);
      setImagePreview(previewURL);
    }

    // Set the selected file
    setupdatepath(selectedFile);
  };
  function refreshPage() {
    window.location.reload(true);
  }
  const updatealbum = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'image',
      updatepath
      // path1.name,
    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": "Bearer" + { token } },
      body:
        formData

    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);

    const data1 = await response1.json();
    const image_ex = data1.image_url;
    console.log(image_ex);


    var updateuser =
    {

      "name": upname,
      "phoneno": upnumber,
      "image": image_ex



    }


    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser

      ),
    };

    const response = await fetch(`${urlchange}/edit_profile`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess12(data.message)
    }
    else {

      showSuccess(data.message);
      handleClose();
      refreshPage();

    }





  }
  const updatealbum1 = async () => {


    var updateuser =
    {



      "name": upname,
      "phoneno": upnumber,
      "image": img
    }


    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser

      ),
    };

    const response = await fetch(`${urlchange}/edit_profile`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess12(data.message)
    }
    else {

      showSuccess(data.message);
      refreshPage();
    }





  }
  const style1234 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 450,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border: "2px solid #FAFAFA"
  };
  const showSuccess = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess12 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
  }
  const getprofile = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_profile`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {

      console.log("no");

    }


    else {


      console.log(data.data)
      setname(data.data.username);
      setnumber(data.data.phoneno);
      setrole(data.data.role);
      setimg(data.data.image);
      setupname(data.data.username);
      setupnumber(data.data.phoneno);
    }
  }
  const handleupdate = () => {
    setImagePreview(img);

    handleopen();
  };

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];

  //   // Display image preview
  //   if (selectedFile) {
  //     const previewURL = URL.createObjectURL(selectedFile);
  //     setImagePreview(previewURL);
  //   }

  //   // Set the selected file
  //   setupdatepath(selectedFile);
  // };



  useEffect(() => {
    getprofile();

  }, []);

  // if (!dataLoaded) {
  //   return <div className="spinner-album" >    <ProgressSpinner /></div>;
  // }
  return (
    <Box sx={{ overflowY: 'auto', height: '92.8vh', backgroundColor: '#0b0d0f' }}>
      <div className="header-grid-acc">
        <div className="header-text-acc">My Account</div>
        <img src={editprofile} onClick={handleupdate} alt="img" className="grid-button-edit" />
        {/* <div className="icons-grid-header">
          <div className="button-div-header">
            <div className="button-text-div" onClick={navigatetoadd}>+ Create Album</div>
            </div><div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
            </div>
            </div> */}
      </div>
      <div className="content-div-acc">
        <div className="content-grid-acc">
          <div className="acc-flex-box">
            <img src={img || placeholder} alt="alt img" className="acc-profile-pic-rectangle" />
            <div className="acc-rectangle">
              <div className="acc-name-user">{name}</div>
              <div className="acc-contact-info">Contact Information</div>
              <div className="acc-line"></div>
              <div className="acc-phone-number-side">Phone : {number}</div>
              <div className="acc-phone-role-side">Role : {role}</div>
            </div>
          </div>
          {/* <div className="acc-deatils-flex">
    <div className="flex-item1"></div>
    <div className="acc-name-user">username</div>
<div className="acc-contact-info">Contact Information</div>

</div> */}

        </div>
      </div>

      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1234}>
          <div className="modal-header-acc">
            <div className="modal-header-text-acc">Edit Account</div>
          </div>
          <div className="modal-input-name1-albums-song-acc">Edit Account</div>

          <img src={imagePreview} alt="img" className="img-upload-preview-albums-acc" />
          <input className="modal-album-imageupdate-acc" type="file" id="formFile" onChange={handleFileChange} />
          <input className="modal-album-updatetext-acc" type="text" value={upname} placeholder="Enter Name" onChange={(e) => {
            handleNameChange(e);
          }} id="formFile" />
          <input className="modal-album-updatetext-acc1" type="tel" value={upnumber} placeholder="Enter Phone Number" onChange={(e) => {
            handleNumberChange(e);
          }} id="formFile" />
          <button className="modal-button-next1-update-acc" onClick={checkandupdate}>Update</button>
          <button className="modal-button-next2-update-acc" onClick={handleCancelClick} >Cancel</button>
        </Box>
      </Modal>

      <Toast ref={toast} />
    </Box>
  );
};

export default Dashboard;
