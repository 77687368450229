import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css'
import React, { useRef } from 'react';
import Alert from '@mui/material/Alert';

import eyeicon from './eye.svg';
import eyeClose from './eyeClose.svg'
import smartlogo from './smartlogo.png';
import { urlchange } from '../../Globalvalues';
import { Toast } from 'primereact/toast';

function Login() {

  sessionStorage.clear();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(true);
  const toast = useRef(null);
  function navigatetoadd() {
    navigate('/dashboard');
  }
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Connection": "keep-alive", "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify(
        // `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
        {
          "email": email,
          "password": password
        }
      ),
    };


    const response = await fetch(`${urlchange}/login`, requestOptions);

    const data = await response.json();

    // console.log(data)

    if (!response.ok) {

      //   <CToaster position="top-right">
      //   <CToast show={showToast} autohide={3000} fade={true}>
      //     <CToastHeader closeButton>
      //       Toast Title
      //     </CToastHeader>
      //     <CToastBody>
      //       This is the content of the toast.
      //     </CToastBody>
      //   </CToast>
      // </CToaster>
      // showErrorNotification('Login failed. Please check your credentials.');
      console.log("no");
      showError(data.message);
    }

    else if (data.role == "Artist") {
      sessionStorage.setItem("token", data.access_token);
      sessionStorage.setItem("user", data.username);
      sessionStorage.setItem("number", data.Phone_number);
      sessionStorage.setItem("role", data.role);
      sessionStorage.setItem("img", data.Profile_image)
      navigate('/dashboard');
    }
    else {
      sessionStorage.setItem("token", data.access_token);
      sessionStorage.setItem("user", data.username);
      sessionStorage.setItem("number", data.Phone_number);
      sessionStorage.setItem("role", data.role);
      sessionStorage.setItem("img", data.Profile_image)

      navigate('/superadmindashboard');
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    submitLogin();
  }

  const showError = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 1000 });
  }
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content', life: 3000 });
  }


  return (
    <div>
      <div className={styles.main_grid}>
        <div className={styles.text_div}>
          <div><img className={styles.smart_logo} src={smartlogo} alt="logo" width="500" height="600" /></div>
          <div><p className={styles.smart_text}>Welcome Back to Smart Vinyl</p></div></div>
        <div className={styles.login_div}>
          <div className={styles.login_grid}>
            <div className={styles.login_text_new}>Login,
              to your account</div>
            <div><input type="text" id="lname" placeholder='Enter Email'  name="lname"  onChange={(e) => setEmail(e.target.value)} className={styles.login_details_user} /></div>
            <div className={styles.login_password_grid} >
              <div><input className={styles.login_password_input} type={passwordShown ? "text" : "password"} id="lname" placeholder='Enter your Password' onChange={(e) => setPassword(e.target.value)} name="lname" /></div>
              <div><img src={passwordShown ? eyeicon : eyeClose} onClick={togglePasswordVisiblity} className={styles.inputusericon} alt="Forest" /></div>
            </div>
            <div><p className={styles.forgot_pass}>Forgot Password ?</p></div>
            <div><button className={styles.login_button} onClick={handleSubmit} type="button">Login</button></div>
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
}

export default Login;
