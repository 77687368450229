import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import { InputSwitch } from 'primereact/inputswitch';
import addicon from "./addicon.svg";


import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import closeicon from "./closeicon.svg";
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import React, { useState, useRef, useEffect } from 'react';
import settings from "./settingsicon.svg";
import { ProgressBar } from 'primereact/progressbar';

import { songsdata } from "../Player/audios";

import { urlchange } from "../../Globalvalues";
import { useLocation } from "react-router-dom";
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';

import { ProgressSpinner } from 'primereact/progressspinner';

import { TabView, TabPanel } from 'primereact/tabview';
import styles from './assign.module.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import zIndex from "@mui/material/styles/zIndex";

const Team = () => {
  const [count, setcount] = useState('');
  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const [opencreate, setOpencreate] = React.useState(false);
  const handleOpen = () => setOpencreate(true);
  const handleClose = () => {
    setOpencreate(false)
    setSelectedCity(null)
    setcount(null);
  }
  const [artistid, setartistid] = useState('');
  const [opencreate1, setOpencreate1] = React.useState(false);
  const handleOpen1 = () => {
    setOpencreate1(true);
    handleClose(false);
  }
  const handleClose1 = () => setOpencreate1(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [opencreate2, setOpencreate2] = React.useState(false);
  const handleOpen2 = () => {
    setOpencreate2(true);
    setOpencreate1(false);
  }

  const handleClose2 = () => setOpencreate2(false);
  const token = sessionStorage.getItem("token");
  const [artist, setartist] = useState();
  const [uploadid, setuploadid] = useState();
  const [path1, setpath1] = useState();
  const toast = useRef(null);
  const [finalalbum, setfinalalbum] = useState('');

  const [selectedCity, setSelectedCity] = useState('');
  var [albums, setalbums] = useState();
  const showSuccess = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess12 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
  }
  const assigndata =
  {


    "artistid": artistid,
    "collectiblesid": finalalbum,
    "collectiblescount": count



  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 250,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border: '2px solid #FAFAFA'
  }
  function navigatetoadd(id) {

    navigate('/collectibledetails', { state: { id } });
  }

  const handleInputChange = (e) => {

    const inputValue = e.target.value.trim();

    if (inputValue === '') {
      setcount(0);
    }
    else {
      const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setcount(parsedValue);
      } else {
        alert("Invalid Count");
        e.target.value = '';
      }
    }
  };

  const handleAssign = () => {
    if (!selectedCity) {
      alert('Please fill all the details.');
      return;
    }
    if (!count) {
      alert('Please fill all the details.');
      return;
    }
    assignproducts();
  }

  const assignproducts = async () => {
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify(
        assigndata
      )
    }
    const response = await fetch(`${urlchange}/assign_collectibles`, requestOptions1);

    const data = await response.json();

    if (!response.ok) {
      console.log(data);
      showSuccess12(data.message);
    }
    else {
      console.log(data);

      showSuccess(data.message);
      getartist();
      handleClose();
    }
  }
  const getartist = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_collectibles_by_artist`, requestOptions1);
    const response1 = await fetch(`${urlchange}/get_collectibles`, requestOptions1);
    const data = await response.json();
    const data1 = await response1.json();
    if (!response.ok) {

      console.log("no");
      setDataLoaded(true);
    }


    else {
      console.log(data.data)
      console.log(data1.data)
      setartist(data.data);
      setalbums(data1.data);
      setDataLoaded(true);
    }
  }
  const uploadtags = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'file',
      path1

    )
    formData.append(
      'artistid',
      uploadid

    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": `Bearer ${token}` },
      body:
        formData

    }

    const response1 = await fetch(`${urlchange}/upload_tag`, requestOptions1);

    const data1 = await response1.json();



    if (!response1.ok) {
      console.log(data1.message);
      showSuccess12(data1.message)
    }
    else {

      showSuccess(data1.message);
      handleClose();
      getartist();
    }





  }
  const handleupdate = (id) => {
    setartistid(id);
    handleOpen();
  };
  const customItemTemplate = (option) => {
    return (
      <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {option.collectible_name}
      </div>
    );
  };
  useEffect(() => {

    getartist();
  }, []);

  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh', backgroundColor: '#000000' }}>    <ProgressSpinner /></div>;
  }

  return (
    <Box style={{ overflow: "auto", height: "92.7vh", backgroundColor: '#000000' }} >
      <div className={styles.main_header_text_grid}>
        <div className={styles.main_haeder_text}>Assign Collectible</div></div>
      <div className={styles.table_flex_main}>
        <div style={{ width: '90%' }}>
          <DataTable value={artist} sortOrder={1} sortField="artist_name" paginator rows={10} tableStyle={{ width: '100%' }} rowHeight="10px" rowClassName={styles.custom_datatable_row}>
            <Column field="artist_name" header="Name" headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA', fontWeight: 'normal' }} style={{ width: '40%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            <Column field="total_collectibles" headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA', fontWeight: 'normal' }} header="Total Collectibles" style={{ width: '40%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column>
            {/* <Column field="totalused" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Used" style={{ width: '20%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column> */}
            {/* <Column field="totalnotused" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Unused" style={{ width: '20%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column> */}
            {/* <Column field="company" headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Remaining" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}></Column> */}
            {/* <Column headerStyle={{ textAlign: 'center', background: '#CCD2D9' }} header="Actions" style={{ width: '25%',height: '10px', fontSize: '11px', fontFamily: 'Roboto'  }} body={(rowData) => ( <img src={addicon}  onClick={() => handleupdate(rowData.userid)}   style={{ transform: 'scale(0.5)',marginRight: '5px' }}/>  )  }></Column> */}
            <Column
              headerStyle={{ textAlign: 'center', background: '#7D8FA5', color: '#FAFAFA', fontWeight: 'normal' }}
              header="Actions"
              style={{ width: '20%', height: '10px', fontSize: '11px', fontFamily: 'Roboto' }}
              body={(rowData) => (
                <>

                  <img
                    src={addicon}
                    alt="Add Icon"
                    className={styles.icon_tooltip}
                    onClick={() => handleupdate(rowData.id)}
                    style={{ transform: 'scale(0.5)', marginRight: '1px' }}
                  />
                  <img
                    src={settings}
                    alt="Another Icon"
                    className={styles.icon_tooltip}
                    onClick={() => navigatetoadd(rowData.id)}
                  // style={{ transform: 'scale(0.5)' }}
                  />
                </>
              )}
            />

          </DataTable>
        </div></div>
      <Modal
        open={opencreate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 5 }}
      >
        <Box sx={style}>
          <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Assign Collectible</div>
            {/* <img src={closeicon} alt="img" onClick={handleClose} className={styles.modal_close_icon} /> */}
            <Dropdown optionLabel="collectible_name" value={selectedCity}
              onChange={(e) => {
                setSelectedCity(e.value);
                // You can also access the selected album's id here and perform any other actions you need.
                var selectedalbumid = e.value.id;
                setfinalalbum(selectedalbumid);
                console.log("Selected Album ID:", selectedalbumid);
              }
              }
              options={albums}
              placeholder="Select a Collectible"
              className={styles.modal_dropdown1}
              // style={{ width: '200px' }}
              itemTemplate={customItemTemplate}
              dropdownStyle={{ maxHeight: '200px', overflowX: 'auto' }} />
            <input type="number" id="lname" placeholder="Enter the count" min="0" onChange={handleInputChange} className={styles.modal_input_artistname} name="lname" />
            <button type="button" className={styles.modal_input_nextbutton} onClick={handleAssign}>Assign</button>
            <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>
      {/* <Modal
        open={opencreate1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Email Address</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname"/>
     <button type="button" className={styles.modal_input_nextbutton} onClick={handleOpen2}>Next</button>
     <button type="button" className={styles.modal_input_cancelbutton}  onClick={handleClose1}>Cancel</button>
     </div>
        </Box>
      </Modal>
      <Modal
        open={opencreate2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <div className={styles.modal_header_albums}><div className={styles.modal_header_text}>Create Artist</div>
     <img src={closeicon} alt="img" className={styles.modal_close_icon}/>
     <div className={styles.modal_input_label}>Enter Phone Number</div>
     <input type="text" id="lname" className={styles.modal_input_artistname} name="lname"/>
     <button type="button" className={styles.modal_input_nextbutton} >Create</button>
     <button type="button" className={styles.modal_input_cancelbutton} onClick={handleClose2}>Cancel</button>
     </div>
        </Box>
      </Modal> */}
      <Toast ref={toast} />
    </Box>
  );
};

export default Team;
