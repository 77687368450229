import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import WebIcon from '@mui/icons-material/Web';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import Mopeim from "../dashboard/productimage";
import mainlogoicon from "./user1.png";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import collectibleicon from './collectiblesicon.svg';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import Collectible1 from '../../Assets/Collectible1.svg'
import CollectibleComp from "../../Assets/CollectibleComp";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);


  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const Item1 = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  
  const handleLogout = () => {
    sessionStorage.clear()
    navigate("/")
  }

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleLogout}
      icon={icon}
    >
      <Typography>{title}</Typography>
      {/* <Link to={to} /> */}
    </MenuItem>
  );
};

const Sidebar1 = () => {
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");
  const [visible, setVisible] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (

    <Box

      sx={{
        "& .pro-sidebar-inner": {
          background: `#0b0d0f !important`,borderRight:'1px solid #292D34'
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menu-item:hover": {
          opacity: 2,
        },
        // "& .pro-inner-item:hover": {
        //   color: "#868dfb !important",
        // },
        // "& .pro-menu-item.active": {
        //   color: "#6870fa !important",
        // },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">



          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="50px"
                  src={mainlogoicon}
                  style={{ cursor: "pointer" }}
                />
              </Box>

            </Box>
          )}

          {role === 'Artist' ?
            <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/dashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}
              <Item
                title="Albums"
                to="/albums"
                // to="/contacts"
                icon={<LibraryMusicIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Webpages"
                to="/webpage"
                icon={<WebIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Collectibles"
                to="/collectibles"
                icon={<CollectibleComp />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
              <Item
                title="My Products"
                to="/products"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="My Account"
                to="/account"
                icon={<AccountBoxIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
              title="Settings"
              to="/settings"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography> */}
              <Item1
                title="Logout"
                // onClick={()=>handleLogout}
                to="/"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}

              />
              {/* <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            </Box> : <Box paddingLeft={isCollapsed ? undefined : "10%"}>
              <Item
                title="Dashboard"
                to="/superadmindashboard"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}
              <Item
                title="Artist"
                to="/artist"
                // to="/contacts"
                icon={<LibraryMusicIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Tags"
                to="/tags"
                icon={<WebIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Collectibles"
                to="/collectibles"
                icon={<CollectibleComp />}
                selected={selected}
                setSelected={setSelected}
              />

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
              <Item
                title="Associate"
                to="/assigncollectible"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="My Account"
                to="/Account"
                icon={<AccountBoxIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
              title="Settings"
              to="/Relogin"
              icon={<SettingsIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

              {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography> */}
              <Item1
                title="Logout"
                // onClick={()=>handleLogout}
                to="/"
                icon={<LogoutIcon />}
                selected={selected}
                setSelected={setSelected}

              />
              {/* <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}

            </Box>}
        </Menu>

      </ProSidebar>
    </Box>
    //     <div className="card flex justify-content-center">
    //     <Sidebar visible={visible} onHide={() => setVisible(false)}>
    //         <h2>Sidebar</h2>
    //         <p>
    //             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    //             Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    //         </p>
    //     </Sidebar>
    //     <Button icon="pi pi-arrow-right" onClick={() => setVisible(true)} />
    // </div>

  );
};

export default Sidebar1;
