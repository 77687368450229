import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Typography from '@mui/material/Typography';
import artistimg from "./artistimg.svg";
import notiicationiconimg from "./notificationicon.svg";
import "./Topbar.css";
import { urlchange } from "../../Globalvalues";
import React, { useState, useRef, useEffect } from 'react';
import Placeholder from "./favicon.jpg";
import { useNavigate } from 'react-router-dom';


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const token = sessionStorage.getItem("token");
  const [name, setname] = useState();

  const [img, setimg] = useState();

  const navigate = useNavigate();

  const handleAccount = () => {
    navigate('/account')
  }


  const getprofile = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_profile`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {

      console.log("no");

    }


    else {


      console.log(data.data)
      setname(data.data.username);

      setimg(data.data.image);
    }
  }
  useEffect(() => {
    getprofile();

  }, []);
  return (
    <Box display="flex" justifyContent="space-between" p={2} backgroundColor='#0b0d0f' borderBottom='1px solid #292D34'>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor='#181A22'
        borderRadius="10px"
        width="70%"
      // maxWidth="40rem"
      // minWidth="20rem"
      >
        <InputBase sx={{ ml: 2, flex: 1, color: '#FAFAFA' }} placeholder="Search" />
        <IconButton type="button" >
          <SearchIcon sx={{ color: '#FAFAFA', backgroundColor: '#181A22' }} />
        </IconButton>
      </Box> */}

      {/* ICONS */}
      <Box display="flex" sx={{ marginRight: "15px",justifyContent:'flex-end' }}>
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <PersonOutlinedIcon   sx={{ color:'#B0C3CC'}}/>
        </IconButton> */}
        <div style={{ display: 'flex', cursor: 'pointer' }} onClick={handleAccount}>
          <img src={img || Placeholder} className="img-style" alt="Girl in a jacket" />
          <Typography sx={{ color: '#FAFAFA', mx: 1, my: 1 }}>{name}</Typography>
        </div>
        {/* <img  src={notiicationiconimg} alt="Girl in a jacket" ></img> */}
        {/* <IconButton>
          <NotificationsOutlinedIcon  sx={{ color:'#B0C3CC' }} />
        </IconButton> */}
        {/* <IconButton>
          <SettingsOutlinedIcon   sx={{ color:'#B0C3CC' }}/>
        </IconButton> */}

      </Box>
    </Box>
  );
};

export default Topbar;
