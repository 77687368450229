import { useState } from "react";
import { Routes, Route,useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Login from "./scenes/Login/Login";
import Addalbum from "./scenes/Addalbum";
import Collectibles from "./scenes/Collectibles";
import Webpage  from "./scenes/Webpages";
import Account from "./scenes/Account";
import Productdetails from "./scenes/Produtdetails";
import Superalbums from "./scenes/Superalbum";
import Supertags from "./scenes/Supertags";
import Tagdetails from "./scenes/Nfcdetails";
import Superdash from "./scenes/Superdash";
import Assign from "./scenes/Assign";
import Relogin from "./scenes/Relogin";
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import MyContext from "./scenes/global/Mycontext";
import Collectibledetails from "./scenes/Collectibledetails";
import Player  from "./scenes/Player/player";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const shouldShowSidebar = () => {
    const { pathname } = location;
    return !['/'].includes(pathname);
  };
  const className = shouldShowSidebar ? 'app' : 'app';
  return (

    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
      
        <div  className={className}>
         {/* <Sidebar isSidebar={isSidebar} /> */}
        
         {shouldShowSidebar() && <Sidebar className="sidebarexample" />}
     {/* !isSidebar &&     <Sidebar isSidebar={isSidebar} />} */}
          <main className="content">
              {/* <Topbar setIsSidebar={setIsSidebar} /> */}  {shouldShowSidebar() && <Topbar />}
     
            <Routes>
           
              <Route path="/" element={<Login/>} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/albums" element={<Team />} />
              <Route path="/songs" element={<Contacts />} />
              <Route path="/player" element={<Player />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/products" element={<Form />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/settings" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/addalbum" element={<Addalbum />} />
              <Route path="/collectibles" element={<Collectibles/>} />
              <Route path="/webpage" element={<Webpage/>} />
              <Route path="/account" element={<Account/>} />
              <Route path="/productdetails" element={<Productdetails/>} />
              <Route path="/artist" element={<Superalbums/>} />
              <Route path="/tags" element={<Supertags/>} />
              <Route path="/tagsdetails" element={<Tagdetails/>} />
              <Route path="/superadmindashboard" element={<Superdash/>} />
              <Route path="/assigncollectible" element={<Assign/>} />
              <Route path="/Relogin" element={<Relogin/>} />
              <Route path="/Collectibledetails" element={<Collectibledetails/>} />
            </Routes>
          </main>
        </div>

        
      </ThemeProvider>
    </ColorModeContext.Provider>
 
  );
}

export default App;
