import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import './collectibles.css';
import editbutton from "./editbutton.svg";
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import collectibleimg from "./collectibleimg.png";
import deletebutton from "./deletebutton.svg";
import hearticon from "./heart.svg";
// import albumicon from "./albumicon.svg";
// import songimg from "./songimg.png";
// import settings from "./settings.svg";
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Paginator } from 'primereact/paginator';
import { urlchange } from "../../Globalvalues";
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import placeholder from "./smartvinyl.svg";
import settings from "./settingsicon.svg";
import favicon from "../../Assets/favicon.jpg"
import Tooltip from '@mui/material/Tooltip';

const Team = () => {

  const navigate = useNavigate();
  let username = sessionStorage.getItem('user');

  useEffect(() => {
    if (username === '' || username === null) {
      navigate('/')
    }
  }, [])

  const theme = useTheme();
  const [delid, setdelid] = useState('');
  const colors = tokens(theme.palette.mode);
  const [first, setfirst] = useState(0);
  const [items, setitems] = useState();
  const [name, setname] = useState('');
  const [description, setdescription] = useState('');
  const [rows, setrows] = useState(5);
  const [dataLoaded, setDataLoaded] = useState(false);
  const token = sessionStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setname('');
    setdescription('');
    setpath1('');
  };
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [path1, setpath1] = useState('');
  const role = sessionStorage.getItem("role");
  const [imagePreview, setImagePreview] = useState(null);
  var [updatepath, setupdatepath] = useState();
  const [collectibleupid, setcollectibleupid] = useState();
  const [updescription, setupdescription] = useState();
  const [upimg, setupimg] = useState();
  const [upname, setupname] = useState();
  const [backupimg, setbackupimg] = useState();
  function refreshPage() {
    window.location.reload(true);
  }
  const accept = (id) => {

    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    deletecollectible(id);
  }
  const confirm2 = (id) => {

    confirmDialog({
      message: 'Do you want to delete this Collectible?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file type is an image
      if (file.type.startsWith('image/') && !file.name.toLowerCase().endsWith('.svg')) {
        setpath1(file);
      } else {
        alert('Please select a valid image file.');
        // Clear the input field
        event.target.value = null;
        setpath1(null);
      }
    }
  }

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];
  const toast = useRef(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    height: 300,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border: '2px solid #FAFAFA'
  };
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    height: 450,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border: '2px solid #FAFAFA'
  };
  const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 320,
    height: 450,
    bgcolor: '#181A22',
    // border: '2px solid #000',
    borderRadius: '0.77rem',
    border: "2px solid white"
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Display image preview
    if (selectedFile) {
      const previewURL = URL.createObjectURL(selectedFile);
      setImagePreview(previewURL);
    }

    // Set the selected file
    setupdatepath(selectedFile);
  };

  const handleUpdate = () => {
    if (!upname.trim()) {
      // Show an error message (you can replace this with your own logic)
      alert('Please enter the name.');
      return;
    }
    if (!updescription.trim()) {
      // Show an error message (you can replace this with your own logic)
      alert('Please enter the description.');
      return;
    }
    // if (!updatepath) {
    //   // Show an error message (you can replace this with your own logic)
    //   alert('Please select the file.');
    //   return;
    // }
    checkandupdate();
  }

  const checkandupdate = () => {

    if (updatepath instanceof File) {

      updatealbum();
    } else {
      updatealbum1();
    }


  }
  const showSuccess1 = (msg) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: msg, life: 3000 });
  }
  const showSuccess2 = () => {
    toast.current.show({ severity: 'error', summary: 'Success', detail: 'Collectible deleted', life: 3000 });
  }
  const showSuccess3 = (msg) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
  }
  //   const items1 = [
  //     {
  //         label: 'Add',
  //         icon: 'pi pi-pencil',
  //         command: () => {
  //             toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
  //         }
  //     },
  //     {
  //         label: 'Update',
  //         icon: 'pi pi-refresh',
  //         command: () => {
  //             toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
  //         }
  //     },
  //     {
  //         label: 'Delete',
  //         icon: 'pi pi-trash',
  //         command: () => {
  //             toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
  //         }
  //     },
  //     {
  //         label: 'React Website',
  //         icon: 'pi pi-external-link',
  //         command: () => {
  //             window.location.href = 'https://facebook.github.io/react/';
  //         }
  //     }
  // ];



  const getcollectibleadmin = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_collectibles`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
      setDataLoaded(true);
    }
    else {


      console.log(data.data)
      setitems(data.data);
      setDataLoaded(true);
    }
  }
  const handlegetcollectiblecall = () => {
    if (role == "superadmin") {

      getcollectibleadmin();
    } else {
      getcollectible();
    }

  };
  const getcollectible = async () => {
    const requestOptions1 = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` }

    }
    const response = await fetch(`${urlchange}/get_collectibles_artist`, requestOptions1);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
      setDataLoaded(true);
    }
    else {


      console.log(data.data)
      setitems(data.data);
      setDataLoaded(true);
    }
  }

  const handleCreateCollectible = () => {
    // Check if the song path is empty or not selected
    if (!name.trim()) {
      alert('Please fill the name.');
      return; // Prevent further execution
    }
    if (!description.trim()) {
      alert('Please fill the description.');
      return; // Prevent further execution
    }
    if (!path1) {
      alert('Please select the file.');
      return; // Prevent further execution
    }
    createstateuser();
  }

  const createstateuser = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'image',
      path1
      // path1.name,
    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": "Bearer" + { token } },
      body:
        formData
    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);

    const data1 = await response1.json();
    const image_ex = data1.image_url;
    console.log(image_ex);

    var datauser =
    {
      "collectiblesname": name,
      "image": image_ex,
      "description": description
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        datauser
      ),
    };

    const response = await fetch(`${urlchange}/create_collectibles`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data);
      showSuccess3(data.message)
    }
    else {
      showSuccess1(data.message);
      setname('');
      setdescription('');
      setpath1('');
      handleClose();
      getcollectibleadmin();
    }
  }

  const deletecollectible = async (id) => {

    // console.log(file)
    const requestOptions1 = {
      method: "DELETE",
      headers: { "Authorization": `Bearer ${token}` },
    }

    const response1 = await fetch(`${urlchange}/deleted_collectibles?collectibles_id=${id}`, requestOptions1);

    const data1 = await response1.json();
    if (!response1.ok) {
      console.log(data1);

    }
    else {
      refreshPage();
      showSuccess2(data1.message);
    }
  }

  const handleupdatealbum = (id) => {
    setcollectibleupid(id.id);
    setupname(id.collectible_name)
    setImagePreview(id.image)
    setbackupimg(id.image)
    setupdescription(id.description)
    handleOpen1();

  };
  const handleviewalbum = (id) => {
    setcollectibleupid(id.id);
    setupname(id.collectible_name)
    setImagePreview(id.image)
    setbackupimg(id.image)
    setupdescription(id.description)
    handleOpen2();

  };
  const updatealbum1 = async () => {
    var updateuser =
    {
      "collectiblesname": upname,
      "image": backupimg,
      "description": updescription
    }


    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser
      ),
    };

    const response = await fetch(`${urlchange}/update_collectibles?collectibles_id=${collectibleupid}`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess3(data.message)
    }
    else {
      console.log(data.message);
      showSuccess1(data.message)
      getcollectibleadmin();
      handleClose1();

    }
  }


  const updatealbum = async () => {
    const formData = new FormData()
    // console.log(file)
    formData.append(
      'image',
      updatepath
      // path1.name,
    )
    const requestOptions1 = {
      method: "POST",
      headers: { "Authorization": "Bearer" + { token } },
      body:
        formData

    }

    const response1 = await fetch(`${urlchange}/upload_image`, requestOptions1);

    const data1 = await response1.json();
    const image_ex = data1.image_url;
    console.log(image_ex);


    var updateuser =
    {


      "collectiblesname": upname,
      "image": image_ex,
      "description": updescription
    }


    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(
        updateuser

      ),
    };

    const response = await fetch(`${urlchange}/update_collectibles?collectibles_id=${collectibleupid}`, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(data.message);
      showSuccess3(data.message)
    }
    else {


      console.log(data.message);
      showSuccess1(data.message);
      handleClose1();
      getcollectibleadmin();
    }





  }


  useEffect(() => {

    // getcollectible();
    handlegetcollectiblecall();
  }, []);
  if (!dataLoaded) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '92.7vh', backgroundColor: '#000000' }}>    <ProgressSpinner /></div>;
  }
  return (

    <Box style={{ overflow: "auto", height: "92.70vh", backgroundColor: '#000000' }} >
      <div className="header-grid-col">
        <div className="header-text-col">Collectibles</div>
        <div className="icons-grid-header-col">
          {role === 'superadmin' && (<div className="button-div-header-col">
            <div className="button-text-div-col" onClick={handleOpen} >+ Collectible</div>
          </div>)}
          {/* <div><img src={editbutton} alt="Girl in a jacket"/></div>
            <div><div><img src={deletebutton} alt="Girl in a jacket"/></div>
            </div> */}

        </div>
      </div>
      <div className="content-div-col" >
        <div className="flex-box-collect">
          {items
            ? items.slice(first, first + rows).map((item) => (<div key={item.id} className="collectible-flex-item">
              <div className="collectible-flex-img"></div>
              <img src={item.image || favicon} className="collectible-flex-img" alt="image missing" />
              <Tooltip title={item.collectible_name} placement="top">
                <div className="colletible-flex-name">{item.collectible_name}</div>
              </Tooltip>
              {/* <div className="collectible-view-count">1k</div>  */}
              {role === 'superadmin' && (<img className="collectible-heart-icon" src={deletebutton} onClick={() => confirm2(item.id)} />)}
              {role === 'superadmin' && (<img className="collectible-edit-icon" src={editbutton} onClick={() => handleupdatealbum(item)} />)}
              {role === 'Artist' && (<img className="collectible-settings-icon" src={settings} onClick={() => handleviewalbum(item)} />)}
              {/* <div className="collectible-description">{item.description}</div> */}
            </div>)) : <p className="text-new-collect">No Collectibles Available</p>}
          {/* <div className="collectible-flex-item">
                <div className="collectible-flex-img"></div>
                <div className="colletible-flex-name">Collectible Name</div>
                <div className="collectible-view-count">1k</div>
                <img  className="collectible-heart-icon" src={hearticon} alt="Girl in a jacket"/>
                <div className="collectible-description">Collectible Short Description</div>
            </div>
            <div className="collectible-flex-item">
                <div className="collectible-flex-img"></div>
                <div className="colletible-flex-name">Collectible Name</div>
                <div className="collectible-view-count">1k</div>
                <img  className="collectible-heart-icon" src={hearticon} alt="Girl in a jacket"/>
                <div className="collectible-description">Collectible Short Description</div>
            </div>
            <div className="collectible-flex-item">
                <div className="collectible-flex-img"></div>
                <img src={collectibleimg } className="collectible-flex-img" alt="Girl in a jacket"/>
                <div className="colletible-flex-name">Collectible Name</div>
                <div className="collectible-view-count">1k</div>
                <img  className="collectible-heart-icon" src={hearticon} alt="Girl in a jacket"/>
                <div className="collectible-description">Collectible Short Description</div>
            </div>
            <div className="collectible-flex-item">
                <div className="collectible-flex-img"></div>
                <img src={collectibleimg } className="collectible-flex-img" alt="Girl in a jacket"/>
                <div className="colletible-flex-name">Collectible Name</div>
                <div className="collectible-view-count">1k</div>
                <img  className="collectible-heart-icon" src={hearticon} alt="Girl in a jacket"/>
                <div className="collectible-description">Collectible Short Description</div>
            </div>
            <div className="collectible-flex-item">
                <div className="collectible-flex-img"></div>
                <img src={collectibleimg } className="collectible-flex-img" alt="Girl in a jacket"/>
                <div className="colletible-flex-name">Collectible Name</div>
                <div className="collectible-view-count">1k</div>
                <img  className="collectible-heart-icon" src={hearticon} alt="Girl in a jacket"/>
                <div className="collectible-description">Collectible Short Description</div>
            </div> */}


        </div>
        {items ? <Paginator first={first} rows={rows} totalRecords={items.length} onPageChange={(e) => {
          setfirst(e.first);
          setrows(e.rows);
        }} /> : <p></p>}
        {/* <div className="content-grid-albums"> */}
        {/* <div className="album-flex-box-albums">
  
          
         {items.slice(first,first+rows).map((item)=>( <div key={item.id} className="album-item-albums">
              <div className="album-name-div-albums">{item.albumname}</div>
            <div className="album-date-div-albums">12/12/2023</div>
            <div className="tag-div-albums"><img src={albumicon} className="album-icon-albums" alt="Girl in a jacket"/><div className="album-text-albums">{item.number_of_songs}</div></div>
            <img src={addicon} className="settings-icon-albums-album" onClick={() => handleid(item.id)}  alt="Girl in a jacket"/>
            <img src={editbutton} onClick={() =>navigatetoadd(item.id)} className="edit-icon-display-album" alt="Girl in a jacket"/>
            <img src={editbutton} className="add-icon-display-album" alt="Girl in a jacket"/>
    <SpeedDial model={items1} className="settings-icon-albums-album" radius={80} type="circle" buttonClassName="p-button-warning" />
            </div>))}

      <Paginator first={first}  rows={rows} totalRecords={items.length} onPageChange={(e)=>{
        setfirst(e.first);
      setrows(e.rows);}}/>
           </div> */}
        {/* </div> */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div className="modal-header-collectible">
              <div className="modal-header-text-collectible">Create Collectible</div>
            </div>
            <input type="text" id="lname" placeholder="Enter name" onChange={(e) => setname(e.target.value)} maxlength="30" className="collectible-modal-input" name="lname" />
            <input type="text" id="lname" className="collectible-modal-textarea" onChange={(e) => setdescription(e.target.value)} maxlength="100" placeholder="Enter description" name="lname" />
            <input type="file" id="lname" className="collectible-modal-image" accept="image/*" onChange={handleImageChange} placeholder="Enter description" name="lname" />
            <button type="button" onClick={handleCreateCollectible} className="collectible-modal-button-create">Create</button>
            <button type="button" onClick={handleClose} className="collectible-modal-button-cancel">Cancel</button>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style1}>
            <div className="modal-header-collectible">
              <div className="modal-header-text-collectible">Edit Collectible</div>
            </div>
            <input type="text" id="lname" placeholder="Enter name" value={upname} onChange={(e) => setupname(e.target.value)} maxlength="30" className="collectible-modal-input1" name="lname" />
            <input type="text" id="lname" className="collectible-modal-textarea1" value={updescription} onChange={(e) => setupdescription(e.target.value)} maxlength="100" placeholder="Enter description" name="lname" />
            <img src={imagePreview} className="collectible-modal-img-preview" />
            <input type="file" id="lname" className="collectible-modal-image1" onChange={handleFileChange} placeholder="Enter description" name="lname" />
            <button type="button" onClick={handleUpdate} className="collectible-modal-button-create1">Update</button>
            <button type="button" onClick={handleClose1} className="collectible-modal-button-cancel1">Cancel</button>
          </Box>
        </Modal>

        <Modal
          open={open2}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="modal-header-collectible">
              <div className="modal-header-text-collectible">View Collectible</div>
            </div>
            <label className="collectible-modal-input2-label">Name:</label>
            <input type="text" id="lname" placeholder="Enter name" value={upname} onChange={(e) => setupname(e.target.value)} maxlength="30" className="collectible-modal-input2" name="lname" />
            <label className="collectible-modal-textarea2-label">Description:</label>
            <textarea type="text" id="lname" className="collectible-modal-textarea2" value={updescription} onChange={(e) => setupdescription(e.target.value)} maxlength="100" placeholder="Enter description" name="lname" />
            <img src={imagePreview} className="collectible-modal-img-preview1" />

            {/* <button type="button" onClick={checkandupdate} className="collectible-modal-button-create1">Create</button> */}
            <button type="button" onClick={handleClose2} className="collectible-modal-button-cancel2">Back</button>
          </Box>

        </Modal>
        <ConfirmDialog />
        <Toast ref={toast} />
      </div>
    </Box>
  );
};

export default Team;
